export const getKeyGivenPreference =
    (mp, preferredKey) =>
        ((mp.has(preferredKey))
            ? preferredKey
            : mp.keys().next().value);

export const removeEntryByKey =
    (mp, key) =>
        ((mp.has(key))
            ? new Map(Array.from(mp).filter(([k]) => k !== key))
            : mp);
