import { spa, userInput, validation } from '#/browser-framework';

import { saveExistingRp, saveNewRp } from '#/ops-facing/junctionService';
import { NotAnEntityName } from '#/ops-facing/controllers/errorController';


const { isEmail, NotAnEmail } = validation;
const { field, inputlock, requiredString } = userInput;

function toEmailList(multilineText) {
    return Array.from(new Set(multilineText.split('\n').map((s) => s.trim()).filter((s) => s)));
}

function transcodeNotificationConfig(recipients, notifyPrefsValue) {
    const { enabled, reminder } = notifyPrefsValue;

    return {
        recipients: toEmailList(recipients),
        sendNotify: {
            relyingParty: {
                completedRequest: enabled.rpCompleteRequest,
                datumShared: enabled.rpDatumShare,
            },
            idOwner: {
                newAttributesRequired: enabled.idoAddlAttrs,
                newRequest: enabled.idoNewRequest,
                readyToShare: enabled.idoReadyToShare,
            },
        },
        sendReminder: {
            relyingParty: {
                completedRequest: reminder.rpCompleteRequest,
                datumShared: reminder.rpDatumShare,
            },
            idOwner: {
                newAttributesRequired: reminder.idoAddlAttrs,
                newRequest: reminder.idoNewRequest,
                readyToShare: reminder.idoReadyToShare,
            },
        },
    };
}


function validateEmailList(v) {
    const list = toEmailList(v);

    if (list.length === 0) {
        return;
    }

    const notEmails = list.filter((e) => !isEmail(e));

    if (notEmails.length === 1) {
        throw NotAnEmail(`Email ${notEmails[0]} is invalid.`);
    } else if (notEmails.length > 1) {
        throw NotAnEmail(`Please double-check the following emails: ${notEmails.join(', ')}`);
    }
}

export function rpFormModel({
    onSave,
}) {
    return inputlock({
        id: 'staged-rp',
        fields: {
            displayName: requiredString('create-rp-display-name-input'),
            entityName: field({
                id: 'create-rp-entity-name-input',
                initialValue: '',
                validate(v) {
                    const normalize = v.replace(/[^\da-z\-]/g, '');
                    if (!v || normalize !== v) {
                        throw NotAnEntityName({
                            message: 'The entity name must be composed of only lower-case English letters, numbers or hyphens',
                        });
                    }

                    if (v[0] === '-') {
                        throw NotAnEntityName({
                            message: 'The entity name cannot begin with a hyphen.',
                        });
                    }

                    if (v.length > 16) {
                        throw NotAnEntityName({
                            message: 'The entity name cannot be longer than 16 characters.',
                        });
                    }

                    if (/^\d+$/.test(v)) {
                        throw NotAnEntityName({
                            message: 'Please use at least one letter.',
                        });
                    }
                },
            }),
            supportEmail: field({
                id: 'create-rp-support-email-address',
                initialValue: '',
                validate(v) {
                    if (!isEmail(v)) {
                        throw NotAnEmail('Invalid email address');
                    }
                },
            }),
            admins: field({
                id: 'create-rp-admin-emails',
                initialValue: '',
                validate: validateEmailList,
            }),
            recipients: field({
                id: 'create-rp-notification-recipients',
                initialValue: '',
                validate: validateEmailList,
            }),
            notifyPrefs: field({
                id: 'notify-prefs',
                initialValue: {
                    enabled: {
                        rpCompleteRequest: true,
                        rpDatumShare: false,
                        idoNewRequest: true,
                        idoAddlAttrs: true,
                        idoReadyToShare: true,
                    },
                    reminder: {
                        rpCompleteRequest: true,
                        rpDatumShare: false,
                        idoNewRequest: true,
                        idoAddlAttrs: true,
                        idoReadyToShare: true,
                    },
                },
                validate() {
                },
            }),
        },
        fire(instance) {
            instance.error = null;
            instance.submitting = true;
            spa.redraw();

            const {
                authTypeIndex,
                authTypes,
                enableVerifyApi,
                fields: {
                    notifyPrefs,
                    displayName,
                    admins,
                    entityName,
                    recipients,
                    supportEmail,
                },
                googleClientId,
                rpExists,
            } = instance;

            const vowSave = (rpExists)
                ? saveExistingRp({
                    notificationConfig: transcodeNotificationConfig(
                        recipients.value,
                        notifyPrefs.value),
                    admins: toEmailList(admins.value),
                    supportEmail: supportEmail.value,
                    name: entityName.value,
                    displayName: displayName.value,
                    verifyApiEnabled: enableVerifyApi,
                    userAuthMode: authTypes[authTypeIndex],
                    userAuthAudience: googleClientId,
                })
                : saveNewRp({
                    notificationConfig: transcodeNotificationConfig(
                        recipients.value,
                        notifyPrefs.value),
                    supportEmail: supportEmail.value,
                    admins: toEmailList(admins.value),
                    name: entityName.value,
                    displayName: displayName.value,
                });

            vowSave
                .then(() => onSave(instance))
                .catch((e) => {
                    /**
                     * determines if the error is due to a network
                     * failure or preexisting rp of the same name
                     */
                    const errorMsg = e.reason.includes('already exists')
                        ? 'There is already an RP with that entity name.'
                        : 'The service seems to be down. Give that another try in a few minutes.';
                    instance.error = new Error(errorMsg);
                })
                .then(() => {
                    instance.submitting = false;
                    spa.redraw();
                });
        },
    }).then((staged) => {
        staged.rpExists = null;
        staged.authTypeIndex = 0;
        staged.enableVerifyApi = true;
        staged.googleClientId = '';
        staged.authTypes = [
            'blindtrust',
            'embedded',
            'evidentapp',
            'googleauth',
        ];

        return staged;
    });
}
