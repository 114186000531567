import { lets, types } from '#/universal-framework/functions';
import { utcTimestampToLocaleString } from '#/universal-framework/dates';
import { bem, m, spa } from '#/browser-framework';
import { KeyValueSet, Overlay, Spinner } from '#/browser-framework/comps';
import { contractcomp, purecomp } from '#/browser-framework/vcomps';

import { idoIdToLocalPart } from '#/ops-facing/controllers/verifications';
import { SearchBar } from './SearchBar';


const { object } = types;

const IdoOpenRecordsNavItem = lets([bem`IdoOpenRecordsNavItem recordTypeContainer`],
    ({ block, recordTypeContainer }) =>
        purecomp(({ ido, verification: { recordType } }) =>
            m(`a${block}`, {
                href: lets([
                    spa.$window.encodeURIComponent(idoIdToLocalPart(ido)),
                    spa.$window.encodeURIComponent(recordType),
                ], (id, rt) => `#!/verifications/${id}/${rt}`),
            }, m(`span${recordTypeContainer}`, recordType))));

const IdoOpenRecordsNavigation = lets([bem`IdoOpenRecordsNavigation heading records`],
    ({ block, heading, records }) =>
        purecomp(({ ido, verifications }) =>
            m(block,
                m(heading, `${verifications.length} open record(s)`),
                m(records, verifications.map((verification, key) =>
                    m(IdoOpenRecordsNavItem, { ido, verification, key }))))));

const IdoOpenRecordsSearchResult = lets([bem`IdoOpenRecordsSearchResult meta records`],
    ({ block, meta, records }) =>
        purecomp(({ data: { email, ido, verifications, lastSubmissionUtcTimestamp }, ordinal }) =>
            m(block, { 'data-ordinal': ordinal },
                m(meta,
                    m(KeyValueSet, {
                        pairs: [
                            ['Email:', email],
                            ['IDO Id:', ido],
                            [
                                'Latest manual verification raised:',
                                utcTimestampToLocaleString(lastSubmissionUtcTimestamp * 1000),
                            ],
                        ],
                    })),
                m(records,
                    m(IdoOpenRecordsNavigation, { ido, verifications })))));

const Results = purecomp(({ results }) =>
    ((results.length === 0)
        ? m('p', 'No results found.')
        : m('div',
            results.map((data, key) =>
                m(IdoOpenRecordsSearchResult, { data, key, ordinal: key })))));


const ManualVerificationSearch =
    purecomp(({ busy, getResults, searchText, onChangeSearch, onSelectResult }) =>
        m('.Overview',
            m(SearchBar, {
                value: searchText,
                oninput: (e) => {
                    return onChangeSearch(e.target.value);
                },
            }),
            (busy)
                ? m(Overlay, m(Spinner))
                : m(Results, { onSelectResult, results: getResults() })));


const APP_STATE_SPEC = {
    viewModel: object,
};

export default contractcomp(
    'OverviewVerifications',
    APP_STATE_SPEC,
    ({ viewModel: { verifications } }) =>
        m(ManualVerificationSearch, verifications));
