import { events, logger, m, router, spa } from '#/browser-framework';
import { hasClaim } from '#/browser-framework/firebase';
import { Snackbar } from '#/browser-framework/comps';

import { DeleteConfirmationDialog } from '#/ops-facing/views/DeleteConfirmationDialog';
import { MacroConfirmationDialog } from '#/ops-facing/views/MacroConfirmationDialog';
import { TimeoutConfirmationDialog } from '#/ops-facing/views/TimeoutConfirmationDialog';
import { rpFormModel } from '#/ops-facing/models/rpFormModel';

import { idoEditor } from './idoEditor';
import { UnauthorizedJunctionUser, errorController } from './errorController';
import { rpController } from './rpController';
import { verificationController } from './verifications';


export function mainController() {
    const iface = {
        errors: errorController(),
        referrer: router.referrer,
    };

    iface.snackbar = Snackbar.makeViewModel();
    iface.idoEditor = idoEditor();

    iface.prepareAdminInterface = () => {
        router.go(iface.referrer || '/rps');

        return Promise.all([
            rpFormModel(iface),
            iface.verifications.searchVerifications(),
        ]).then(([formModel]) => {
            iface.staged = formModel;

            events.on('retry-fulfilments', iface.idoEditor.retrySharingFulfilments);
            events.on('delete-attribute-data', (attrIds) => {
                iface.dialogComp = {
                    oninit() {
                        window.addEventListener('popstate', (e) => {
                            e.preventDefault();
                            iface.dialogComp = null;
                        });
                    },
                    view() {
                        return m(DeleteConfirmationDialog, {
                            attrIds,
                            onconfirm: async () => {
                                try {
                                    await iface.idoEditor.deleteAttributeDatums(attrIds);
                                    iface.dialogComp = null;
                                    spa.redraw();
                                } catch (error) {
                                    iface.dialogState.error = error;
                                }
                            },
                            oncancel() {
                                iface.dialogComp = null;
                            },
                        }, m('em', `You are about to delete ${attrIds.length} attribute(s).`));
                    },
                };
            });

            events.on('delete-request', (requestId) => {
                iface.dialogComp = {
                    view() {
                        return m(DeleteConfirmationDialog, {
                            onconfirm: async () => {
                                try {
                                    await iface.idoEditor.deleteRequest(requestId);
                                    iface.dialogComp = null;
                                    spa.redraw();
                                } catch (error) {
                                    iface.dialogState.error = error;
                                }
                            },
                            oncancel() {
                                iface.dialogComp = null;
                            },
                        }, m('p', 'You are about to delete request ', m('strong', requestId)));
                    },
                };
            });

            events.on('timeout-request', (requestId) => {
                iface.dialogComp = {
                    view() {
                        return m(TimeoutConfirmationDialog, {
                            onconfirm: async () => {
                                try {
                                    await iface.idoEditor.timeoutRequest(requestId);
                                    iface.dialogComp = null;
                                    spa.redraw();
                                } catch (error) {
                                    iface.dialogState.error = error;
                                }
                            },
                            oncancel() {
                                iface.dialogComp = null;
                            },
                        }, m('p', 'You are about to timeout a request ', m('strong', requestId)));
                    },
                };
            });

            events.on('run-macro', (macroName) => {
                iface.dialogComp = MacroConfirmationDialog;
                iface.dialogState = {
                    macroName,
                    onconfirm: async () => {
                        try {
                            await iface.idoEditor.executeMacro(macroName);
                            iface.dialogComp = null;
                            spa.redraw();
                        } catch (error) {
                            iface.dialogState.error = error;
                        }
                    },
                    oncancel() {
                        iface.dialogComp = null;
                    },
                };
            });
        });
    };

    iface.prepareContractorInterface = () => {
        router.go(iface.referrer || '/verifications');

        return iface.verifications.searchVerifications();
    };

    iface.attachSession = spa.redrawAfter(({ user, tokenResult }) => {
        iface.user = user || 'guest';

        iface.isUserAdmin = hasClaim(tokenResult, 'junctionAdmin');
        iface.isUserContractor = hasClaim(tokenResult, 'junctionContractor');

        logger.info(`User ascertained: ${JSON.stringify(iface.user)}`);

        if (user) {
            return (iface.isUserAdmin)
                ? iface.prepareAdminInterface()
                : (iface.isUserContractor)
                    ? iface.prepareContractorInterface()
                    : iface.errors.showError(UnauthorizedJunctionUser());
        } else {
            iface.rps.list = [];
            iface.staged = null;
            iface.verifications.collection = new Map();

            router.go('/login');
        }
    });

    return rpFormModel({
        onSave(instance) {
            iface.rps.list = [];
            iface.rps.dirty = true;

            // Show only a newly created RP as feedback
            if (!instance.rpExists) {
                iface.rps.searchText = instance.fields.displayName.value;
            }

            router.go('/rps');
        },
    }).then((staged) => {
        iface.rps = rpController({ staged });
        iface.verifications = verificationController();

        return iface;
    });
}
