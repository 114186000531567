import { m } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';


const Form = {
    view({
        attrs: {
            f,
        },
    }) {
        return m('.EnumSelection', { 'class': (f.showError && f.value === null) ? 'fg-danger' : '' },
            m('label', { for: f.metadata.memberName }, f.labelText),
            m(Dropdown, {
                value: f.key === null
                    ? 0
                    : f.options.indexOf(f.key) + 1,
                id: f.metadata.memberName,
                options: f.options,
                placeholder: 'Select a value',
                onblur() {
                    f.showError = true;
                },
                select(index, actual) {
                    f.showError = false;
                    [f.key, f.value] = (f.values[actual])
                        ? f.values[actual]
                        : [null, null];
                },
            }));
    },
};


export default function EnumSelection(plugin) {
    plugin.view = Form;
    plugin.showError = false;
    plugin.maySubmit = () => (
        plugin.metadata.optional ||
        plugin.key in plugin.metadata.options
    );
    plugin.values = Object.entries(plugin.metadata.options).sort(([a], [b]) => (a.localeCompare(b)));
    plugin.options = plugin.values.map(([name]) => name);
    plugin.key = plugin.value = null;

    return plugin;
}
