import SingleLineTextInput from './SingleLineTextInput';


export default function PostalCode(plugin, metadata) {
    const extended = SingleLineTextInput(plugin, metadata);

    const range = metadata.constraints.find(({ constraintType }) => constraintType === 'RangeConstraint');

    plugin.minVal = 0;
    plugin.maxVal = 99999;

    if (range) {
        Object.assign(plugin, range);
    }

    extended.maySubmit = function maySubmit() {
        const N = Number(extended.value);

        return (
            Number.isInteger(N) &&
            N >= plugin.minVal &&
            N <= plugin.maxVal
        );
    };

    return extended;
}
