import { SingleLineTextInputView } from './SingleLineTextInput';


export default function IntegerInput(plugin) {
    plugin.view = SingleLineTextInputView;
    plugin.value = '';
    plugin.showError = false;
    plugin.maySubmit = () => {
        return (
            (plugin.metadata.optional && !plugin.value === '') ||
            (Number.isInteger(Number(plugin.value)))
        );
    };

    return plugin;
}
