import { m, router, tx } from '#/browser-framework';
import { Button, Dropdown, Overlay, Spinner } from '#/browser-framework/comps';

import { NotificationControlPanel } from './NotificationControlPanel';


const fieldSelector = tx('.RelyingPartyForm__field');

const LabelInputPair = {
    view({
        children,
        attrs: {
            multiline = false,
            readonly,
            field,
        },
    }) {
        return m(fieldSelector,
            m(`label[for="${field.id}"]`, children),
            m((multiline) ? 'textarea' : `input${tx({'-readonly': readonly})}[type="text"]`, {
                id: field.id,
                autocomplete: 'off',
                autocorrect: 'off',
                autocapitalize: 'off',
                spellcheck: false,
                'data-harness': field.id,
                value: field.value,
                readonly,
                onblur() {
                    field.showError = Boolean(field.value);
                },
                oninput({target: {value}}) {
                    if (!readonly) {
                        field.showError = false;
                        field.value = value;
                    }
                },
            }),
            (field.errorText) && m('p.-fg-danger', field.errorText));
    },
};


export const EditRelyingPartyForm = {
    view({
        attrs: spa,
    }) {
        const {
            viewModel: {
                rps: {
                    staged,
                    focusedRp,
                },
            },
        } = spa;

        if (!staged) {
            return null;
        }

        const checkboxOpts = {
            id: 'enable-verifyapi',
            type: 'checkbox',
            onchange(e) {
                staged.enableVerifyApi = e.target.checked;
            },
        };

        if (staged.enableVerifyApi) {
            checkboxOpts.checked = true;
        }

        return m('.RelyingPartyForm',
            m('h3.RelyingPartyForm__status', 'Status: ', m(`span${tx({
                'fg-brandprimary': focusedRp.status === 'Active',
                'fg-danger': focusedRp.status === 'Pending',
            })}`, focusedRp.status)),
            (staged.error) &&
                m('.RelyingPartyForm__error', staged.error.message),


            m(LabelInputPair, {
                staged,
                field: staged.fields.displayName,
            }, 'Customer Display Name'),

            m(LabelInputPair, {
                staged,
                readonly: staged.rpExists,
                field: staged.fields.entityName,
            }, 'Entity Name'),

            m(LabelInputPair, {
                staged,
                readonly: staged.rpExists,
                field: staged.fields.supportEmail,
            }, 'Support Email Address'),


            m(fieldSelector,
                m('input', checkboxOpts),
                m('label', ' Enable VerifyAPI')),

            m(fieldSelector,
                m('label', 'VerifyAPI key'),
                m('input.-readonly', { readonly: true, value: focusedRp._objective.verifyApiKey || 'Not set.' })),

            m(fieldSelector,
                m('label', 'User authentication type'),
                m(Dropdown, {
                    id: 'auth-types',
                    value: staged.authTypeIndex,
                    options: staged.authTypes,
                    select(i) {
                        staged.authTypeIndex = i;
                    },
                })),

            m(LabelInputPair, {
                staged,
                multiline: true,
                field: staged.fields.admins,
            }, 'Admin emails (Enter one per line. Duplicates will be removed.)'),


            m(NotificationControlPanel, {
                notifyPrefs: staged.fields.notifyPrefs,
            }),

            m(LabelInputPair, {
                staged,
                multiline: true,
                field: staged.fields.recipients,
            }, 'Notification recipients (Enter one per line. Duplicates will be removed.)'),

            (staged.authTypes[staged.authTypeIndex] === 'googleauth') && m(fieldSelector,
                m('label', 'Google Client ID'),
                m('input', {
                    id: 'google-client-id',
                    value: staged.googleClientId,
                    oninput(e) {
                        staged.googleClientId = e.target.value;
                    },
                })),

            m('.RelyingPartyForm__controls',
                m(Button, {
                    flags: {
                        danger: true,
                    },
                    onpress: () => router.go('/rps'),
                }, 'Cancel'),
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    'disabled': !staged.maySubmit,
                    onpress: staged.submit,
                }, 'Confirm')
            ),
            (staged.submitting) && m(Overlay, m(Spinner)));
    },
};
