import moment from 'moment';
import { m } from '#/browser-framework';
import { DatePicker } from '#/browser-framework/comps';

import { SingleLineTextInputView } from './SingleLineTextInput';


const FORMAT = 'MM/DD/YYYY';
const ERR_MSG_DATE_FORMAT = `Use ${FORMAT} format`;
const ERR_MSG_FUTURE_DATE = 'The date must be in the past';

export const DateInputForm = {
    view({
        attrs,
    }) {
        return m('.DateInput',
            m(SingleLineTextInputView, attrs,
                m(DatePicker, {
                    id: attrs.f.metadata.memberName,
                    value: attrs.f.value,
                    // if the field has been explicitly set as false, it will be readonly
                    readonly: (attrs.f.metadata.includesDate === false),
                    oninput: (e) => {
                        attrs.f.showError = false;
                        attrs.f.value = e.target.value;
                    },
                    onblur: () => {
                        attrs.f.showError = !attrs.f.maySubmit();
                    },
                    select(d) {
                        attrs.f.value = moment(d).format(FORMAT);
                    },
                })));
    },
};

/**
 * DateInput is a component that shows a single line of text with a date picker.
 * The input plugin is decorated with methods that turn it into a vcomponent.
 *
 * @param plugin
 * @return Returns the plugin that was input.
 */
export default function DateInput(plugin) {
    plugin.view = DateInputForm;
    plugin.showError = false;
    plugin.placeholder = FORMAT;
    plugin.errorText = ERR_MSG_DATE_FORMAT;
    plugin.external_errorText = null;
    plugin.nonExpiring = false;

    plugin.loadValue = function loadValue(date) {
        const {day, month, year} = date;

        const mmt = moment();

        mmt.set('year', year);
        mmt.set('date', day);
        mmt.set('month', month - 1);

        plugin.value = mmt.format(FORMAT);
    };

    plugin.maySubmit = () => {
        let error;

        const mInputDate = moment(plugin.value, FORMAT, true);

        if (plugin.external_errorText) {
            error = new Error(plugin.external_errorText);
        }

        const isEmptyButValid = (plugin.metadata.optional && !plugin.value);
        if (!error && !(plugin.metadata.includesDate === false) && !isEmptyButValid && !mInputDate.isValid()) {
            error = new Error(ERR_MSG_DATE_FORMAT);
        }

        const isFutureDate = mInputDate.isAfter(moment(), 'day');
        if (!error && !(plugin.metadata.includesDate === false) && plugin.metadata.preventFutureDates && isFutureDate) {
            error = new Error(ERR_MSG_FUTURE_DATE);
        }

        (error) ? (plugin.errorText = error.message) : (plugin.errorText = null);
        // Ok to submit if no error.
        return (error === undefined);
    };

    plugin.toggleNonExpiringValue = () => {
        plugin.nonExpiring = !plugin.nonExpiring;
    };

    plugin.encode = () => {
        if (plugin.nonExpiring === true) {
            return {
                $objectType: 'date',
                day: 1,
                month: 1,
                year: 2099,
            };
        }


        if (!plugin.value) {
            return null;
        }

        const mmt = moment(plugin.value, FORMAT, true);

        if (!mmt.isValid()) {
            throw new Error('Invalid date');
        }

        return {
            $objectType: 'date',
            day: mmt.date(),
            month: 1 + mmt.month(),
            year: mmt.year(),
        };
    };

    return plugin;
}
