import { async, m, router, spa } from '#/browser-framework';
import { SessionMenu } from '#/ops-facing/views/SessionMenu';
import { junctionClient } from '#/ops-facing/junctionService';
import { Snackbar } from '#/browser-framework/comps';


// This literally just POSTs the pasted data to a route where it is then parsed

function copyToGlorifiedClipboard(data) {
    return junctionClient({
        method: 'POST',
        url: '/manualVerifications/driverLicense',
        data,
    });
}

function pollForPasteFromGlorifiedClipboard(onData) {
    return async.poll({
        peri() {
            return junctionClient({
                method: 'DELETE',
                url: '/manualVerifications/driverLicense',
            });
        },
        post(value) {
            if (value !== null) {
                onData(value);
            }

            return 1500;
        },
    });
}


const GlorifiedClipboard = {
    view() {
        return [
            m(SessionMenu, { user: spa.viewModel.user }),
            m('textarea.GlorifiedClipboard', {
                placeholder: 'Paste PDF417 Data',
                onpaste(e) {
                    copyToGlorifiedClipboard({
                        'pdf417Data': e.clipboardData.getData('text'),
                    })
                        .then(() => {
                            e.target.value = '';
                            spa.viewModel.snackbar.display('Sent.', true);
                        });
                },
            }),
            m(Snackbar, spa.viewModel.snackbar),
        ];
    },
};

let _poll;

export const stopListening = () => {
    if (_poll) {
        _poll.stop();
        _poll = null;
    }
};

export const startListening = (ondata) => {
    stopListening();
    _poll = pollForPasteFromGlorifiedClipboard(ondata);
    return _poll.start();
};

export function installBackChannel() {
    router.on('DL Scan aid', '/gc', () => {
        spa.setView(GlorifiedClipboard);
    });
}
