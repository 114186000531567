export const ACUANT_TYPE_SUFFIX = '.acuant_v2_response';

export const filterByAlertLevel = (alerts) => {
    return alerts.filter((alert) => {
        return alert.Result > 1;
    });
};

export const prepareListOfAlerts = (recordData) => {
    return recordData.values
        .reduce((acc, record) => {
            const hasAlerts = record.attributeType.endsWith(ACUANT_TYPE_SUFFIX)
                && record.content
                && record.content.Alerts
                && Array.isArray(record.content.Alerts)
                && (deploy.WEB_PUBLIC_VARIANT === 'development' || /int.evidentid/.test(deploy.WEB_PUBLIC_JUNCTION_SERVICE_URL_PREFIX));
            return hasAlerts
                ? acc.concat(filterByAlertLevel(record.content.Alerts))
                : acc;
        }, []);
};
