import { m, tx, spa, strings } from '#/browser-framework';
import { FileDropbox } from '#/browser-framework/comps';
import { createBinaryDataFromFile } from '#/evident-attributes/encoding/documents';


export const SingleFileUploadView = {
    view({
        attrs: {
            f,
        },
    }) {
        const id = f.metadata.memberName;

        return m(tx('.SingleFileUpload', {invalid: f.showError && !f.maySubmit()}),
            m('label', { for: id }, f.labelText),
            m(FileDropbox, {
                id,
                acceptedExtensions: ['jpg', 'jpeg', 'png'],
                onchange(files) {
                    f.setFile(files[0]);
                },
            }, (f.file)
                ? `I have "${(/\s/g.test(f.file.name)) ? f.file.name : strings.midellipsis(f.file.name, 20)}".
                   Upload or drag in a replacement if desired.`
                : []));
    },
};

export default function SingleFileUpload(plugin) {
    plugin.view = SingleFileUploadView;
    plugin.file = null;
    plugin.showError = false;
    plugin.submission = null;

    plugin.maySubmit = () => {
        return plugin.metadata.optional || Boolean(plugin.submission);
    };

    plugin.encode = () => {
        return plugin.submission;
    };

    plugin.setFile = spa.redrawAfter((f) => {
        plugin.file = f;

        if (f) {
            createBinaryDataFromFile(f)
                .then((bd) => {
                    plugin.submission = bd;
                    plugin.showError = false;
                })
                .catch((e) => {
                    plugin.error = e;
                    plugin.showError = true;
                    plugin.submission = null;
                });
        } else {
            plugin.showError = true;
            plugin.submission = null;
        }
    });

    return plugin;
}
