import { m } from '#/browser-framework';

export const Display = {
    view({
        attrs: {
            a,
        },
    }) {
        return m('pre.JsonDump',
            JSON.stringify(a, null, 4));
    },
};

export default function JsonDump(plugin) {
    plugin.view = Display;

    return plugin;
}
