import { events, m } from '#/browser-framework';
import { Button, LabeledCheckbox } from '#/browser-framework/comps';

export const AttributeSharingContexts = {
    view({
        attrs: {
            retrySelection,
            sharingContexts,
        },
    }) {
        return m('table.AttributeSharingContexts',
            m('thead',
                m('tr',
                    m('th', m(LabeledCheckbox, {
                        onchange: retrySelection.toggleAll,
                        state: retrySelection.areAllOn(),
                    })),
                    m('th', 'ASA Status'),
                    m('th', 'ASA ID'),
                    m('th', 'ASA Attribute Type'),
                    m('th', 'ASA Expiration'),
                    m('th', 'ASF Status'),
                    m('th', 'ASF Status Detail'),
                    m('th', 'ASF Next Check'),
                    m('th', 'Exists?'))),
            m('tbody',
                sharingContexts.map((sc, key) =>
                    m('tr', { key },
                        m('td', m(LabeledCheckbox, {
                            onchange: (state) => retrySelection.setOne(sc.asaId, state),
                            state: retrySelection.isOn(sc.asaId),
                        })),
                        m('td', sc.asaStatus),
                        m('td', sc.asaId),
                        m('td', sc.asaAttrType),
                        m('td', sc.asaExpiration),
                        m('td', sc.asfStatus),
                        m('td', {
                            title: sc.attributesRequired,
                        }, sc.asfStatusDetail),
                        m('td', sc.asfNextCheck),
                        m('td', sc.exists ? 'Yes' : 'No')))));
    },
};

export const AttributeInstanceListing = {
    view({
        attrs: {
            attributeDataArray,
            deleteSelection,
        },
    }) {
        return (attributeDataArray.length === 0)
            ? m('p', 'There are no attribute data items stored for this IDO.')
            : m('div.AttributeInstanceListing',
                m('div.HeaderButtons', m(Button, {
                    flags: {
                        danger: true,
                    },
                    disabled: deleteSelection.getSelected().length === 0,
                    onpress() {
                        events.emit('delete-attribute-data', deleteSelection.getSelected());
                    },
                }, 'Delete')),
                m('table', m('thead',
                    m('tr',
                        m('th', m(LabeledCheckbox, {
                            onchange: deleteSelection.toggleAll,
                            state: deleteSelection.areAllOn(),
                        })),
                        m('th', 'Attribute Type'),
                        m('th', 'Issued at'),
                        m('th', 'Source'),
                        m('th', 'ID'))),
                m('tbody', attributeDataArray.map((attr, key) =>
                    m('tr', {key},
                        m('td', m(LabeledCheckbox, {
                            state: deleteSelection.isOn(attr.id),
                            onchange: (state) => deleteSelection.setOne(attr.id, state),
                        })),
                        m('td', attr.type),
                        m('td', attr.issuedAtText),
                        m('td', attr.sourceText),
                        m('td', attr.id))))));
    },
};
