import { timestampObjectToLocaleString } from '#/universal-framework/dates';
import { alphabetize } from '#/universal-framework/strings';
import { optionset } from '#/browser-framework/behaviors';
import { fulfilmentStatusStringFromAsa } from '#/evident-attributes/attrStateViews';


export const requestController = (rpr) => ({
    rprId: rpr.id,
    rpDisplayName: rpr.display_name,
    createdAtText: timestampObjectToLocaleString(rpr.created_at),
    summaryText: rpr.summary,
    descriptionText: rpr.description,
    notesText: rpr.notes,

    sharingContexts: rpr.attribute_sharing_agreements
        .sort(({ attribute_type: a }, { attribute_type: b }) => a.localeCompare(b))
        .map((asa) => ({
            asaId: asa.asa_id,
            asaStatus: asa.status,
            asaAttrType: asa.attribute_type,
            asaExpiration: timestampObjectToLocaleString(asa.expires_at),
            asfStatus: asa.fulfilment.status,
            asfStatusDetail: asa.fulfilment.status_detail,
            attributesRequired: alphabetize(asa.fulfilment.attributes_required || []).join('\n'),
            exists: asa.exists,
            asfNextCheck: fulfilmentStatusStringFromAsa(asa),
        })),

    retrySelection: optionset({
        initialState: rpr.attribute_sharing_agreements.reduce((p, { asa_id: id }) =>
            Object.assign({}, p, { [id]: false }), {}),
    }),
});
