import { m, logger } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';
import Geonames from 'geonames.js';

const geonames = new Geonames({ username: 'evident', lan: 'en', encoding: 'JSON' });

/*
Entities referenced below:
----
Antarctica
Guam
American Samoa
Northern Mariana Islands
Puerto Rico
US Virgin Islands
*/

export const US_GEONAME_DATA = {
    ISO3: 'USA',
    ID: 6252001,
    INDEX: 233,
};
const NOT_COUNTRIES_ISO3 = [
    'ATA',
    'GUM',
    'ASM',
    'MNP',
    'PRI',
    'VIR',
];

const US_DEPENDENCIES = [
    'Puerto Rico',
    'Guam',
    'American Samoa',
    'US Virgin Islands',
    'Northern Mariana Islands',
];

/**
 * @typedef {Object} countryGeoname
 * @property {string} countryName - the english language name of the country
 * @property {string} isoAlpha3 - the 3 letter country code for the country
 * @property {number} geonameId - the unique id for that country in the geoname api
 */

const sortCountryNames = (a, b) => {
    /**
     * @param {countryGeoname} a
     * @param {countryGeoname} b
    */
    return a.countryName.localeCompare(b.countryName);
};

export const getCountries = async () => {
    // if there is a country list in local storage, use that instead making a network request
    if (localStorage.countryList) {
        return JSON.parse(localStorage.countryList);
    } else {
        try {
            const countries = await geonames.countryInfo();
            const stringifiedGeonames = JSON.stringify(countries.geonames
                .filter(({ isoAlpha3 }) => (!NOT_COUNTRIES_ISO3.includes(isoAlpha3)))
                .map(({ countryName, isoAlpha3, geonameId }) => ({ countryName, isoAlpha3, geonameId }))
                .sort((a, b) => sortCountryNames(a, b)));
            localStorage.setItem('countryList', stringifiedGeonames);
            return JSON.parse(localStorage.countryList);
        } catch (error) {
            logger.error(error);
        }
    }
};

const handleUSExceptions = (states, territories) => ([...states, ...territories].sort());

// getGeochildren handles the retrieval of administrative subdivisions
// if a particular stateList is not found in localStorage, fetch it and add it to localStorage

export const getGeoChildren = async (geonameId) => {
    /**
     * @param {number} geonameId - a number that ids a particular geoname
     * @return {array} an array of administrative subdivisions
     *  */

    if (localStorage[`stateList-${geonameId}`]) {
        return JSON.parse(localStorage[`stateList-${geonameId}`]);
    } else {
        let geoChildren = [];
        try {
            const geonamesResponse = await geonames.children({ geonameId });
            geoChildren = geonamesResponse.geonames.map(({ toponymName }) => (toponymName));

            if (geonameId === US_GEONAME_DATA.ID) {
                geoChildren = handleUSExceptions(geoChildren, US_DEPENDENCIES);
            }
            const stringifiedGeochildren = JSON.stringify(geoChildren);
            localStorage.setItem(`stateList-${geonameId}`, stringifiedGeochildren);
            return geoChildren.sort();
        } catch (error) {
            logger.error(error);
        }
    }
};

export const CountryStateDropdowns = {
    allCountries: [],
    country: US_GEONAME_DATA.ISO3,
    countryIndex: US_GEONAME_DATA.INDEX,
    stateIndex: 0,
    administrativeChild: '',
    administrativeChildren: [],
    async oninit() {
        this.allCountries = await getCountries();
        this.administrativeChildren = await getGeoChildren(US_GEONAME_DATA.ID);
        m.redraw();
    },
    view({
        attrs: {
            state,
        },
    }) {
        return m('.CountryStateDropdowns',
            m('label', {
                key: 'country',
                class: 'country',
            },
            m('div',
                'Country',
                (state.countryRequired)
                    ? ' *'
                    : ''),
            m(Dropdown, {
                placeholder: 'Select a country',
                options: this.allCountries.map(({ countryName }) => (countryName)),
                class: 'country',
                value: this.countryIndex,
                select: async (index, actual) => {
                    this.country = this.allCountries[actual].isoAlpha3;
                    this.countryIndex = index;
                    this.stateIndex = 0;
                    this.administrativeChild = '';
                    this.administrativeChildren = [];
                    state.updateValue('country', this.country);
                    this.administrativeChildren = await getGeoChildren(this.allCountries[actual].geonameId);
                    m.redraw();
                },
            })),
            (state.showStateField)
                ? m('label', {
                    key: 'state',
                    class: 'state',
                },
                m('div',
                    'State',
                    (this.country === US_GEONAME_DATA.ISO3)
                        ? ' *'
                        : ''),
                m(Dropdown, {
                    placeholder: this.administrativeChildren && this.administrativeChildren.length ? 'Select a Subdivision' : '...',
                    options: this.administrativeChildren,
                    class: 'state',
                    value: this.stateIndex,
                    select: (index, actual) => {
                        this.state = this.administrativeChildren[actual];
                        this.stateIndex = index;
                        state.updateValue('state', this.state);
                    },
                }))
                : ''
        );
    },
};
