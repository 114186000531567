import { m } from '#/browser-framework';
import { Button, Dropdown } from '#/browser-framework/comps';


export const CommandSelection = {
    selected: null,
    selectedIndex: 0,
    view({
        state,
        attrs: {
            commands,
            execute,
            placeholder = 'Select a command...',
        },
    }) {
        return m('.CommandSelection',
            m(Dropdown, {
                placeholder,
                options: commands,
                select(index, actual) {
                    state.selectedIndex = index;
                    state.selected = commands[actual];
                },
            }),
            m(Button, {
                flags: {
                    primary: true,
                },
                disabled: !execute || !state.selected,
                onpress: () => execute(state.selected),
            }, 'Run'));
    },
};
