import { m, events } from '#/browser-framework';
import { Button } from '#/browser-framework/comps';

import { CommandSelection } from './CommandSelection';
import { AttributeSharingContexts, AttributeInstanceListing } from './AttributeControls';
import { RequestSummary } from './RequestSummary';

const NamedSection = 'section.NamedSection';
const RequestAndAttrPanel = '.RequestAndAttrPanel';

const IdoSummary = {
    view({
        attrs: {
            email,
            idOwnerId,
            domain,
        },
    }) {
        return m('table.IdoSummary',
            m('tbody',
                m('tr',
                    m('th', 'Email:'),
                    m('td', email)),
                m('tr',
                    m('th', 'ID Owner ID:'),
                    m('td', idOwnerId)),
                m('tr',
                    m('th', 'Domain:'),
                    m('td', domain))));
    },
};


export const IdoDetail = {
    view({
        attrs: {
            viewModel: {
                idoEditor,
            },
        },
    }) {
        const {
            detail,
            requests,
            commands,
            datums,
        } = idoEditor;

        return m('.Overview.IdoDetail',
            m(NamedSection,
                m('h2.crisp',
                    'IDO Information (', m('span.fg-brandprimary', {
                        style: 'cursor: pointer',
                        onclick() {
                            if (!idoEditor.isRefreshing()) {
                                idoEditor.refresh();
                            }
                        },
                    }, 'Refresh'), ')'),
                m(IdoSummary, {
                    email: detail.email,
                    idOwnerId: detail.id,
                    domain: detail.domain,
                }),
                m(CommandSelection, (commands)
                    ? commands
                    : {
                        placeholder: 'Loading macros...',
                        commands: [],
                        execute: null,
                    })),
            m(NamedSection,
                m('h2.crisp', 'Requests'),
                (requests.length === 0)
                    ? m('p', 'There are no requests out for this IDO.')
                    : requests.map((rpr, key) => m(RequestAndAttrPanel, { key },
                        m(RequestSummary, {
                            rprId: rpr.rprId,
                            rpDisplayName: rpr.rpDisplayName,
                            createdAtText: rpr.createdAtText,
                            summaryText: rpr.summaryText,
                            descriptionText: rpr.descriptionText,
                            notesText: rpr.notesText,
                        }),
                        m('.kebab.HeaderButtons',
                            m(Button, {
                                flags: {
                                    primary: true,
                                },
                                disabled: rpr.retrySelection.getSelected().length === 0,
                                onpress() {
                                    events.emit('retry-fulfilments',
                                        rpr.retrySelection.getSelected());
                                },
                            }, 'Retry selected'),
                            m(Button, {
                                flags: {
                                    danger: true,
                                },
                                onpress() {
                                    events.emit('delete-request', rpr.rprId);
                                },
                            }, 'Delete Request'),
                            m(Button, {
                                flags: {
                                    caution: true,
                                },
                                onpress() {
                                    events.emit('timeout-request', rpr.rprId);
                                },
                            }, 'Timeout Request')),
                        m(AttributeSharingContexts, {
                            rprId: rpr.rprId,
                            sharingContexts: rpr.sharingContexts,
                            retrySelection: rpr.retrySelection,
                        })))),
            m(NamedSection,
                m('h2.crisp', 'Attribute instances'),
                m(AttributeInstanceListing, datums)));
    },
};
