import { m, router, tx } from '#/browser-framework';
import { Button, Overlay, Spinner } from '#/browser-framework/comps';

import { NotificationControlPanel } from './NotificationControlPanel';


const LabelInputPair = {
    view({
        children,
        attrs: {
            multiline = false,
            readonly,
            field,
        },
    }) {
        return m(tx('.RelyingPartyForm__field'),
            m(`label[for="${field.id}"]`, children),
            m((multiline) ? 'textarea' : `input${tx({'-readonly': readonly})}[type="text"]`, {
                id: field.id,
                autocomplete: 'off',
                autocorrect: 'off',
                autocapitalize: 'off',
                spellcheck: false,
                'data-harness': field.id,
                value: field.value,
                readonly,
                onblur() {
                    field.showError = Boolean(field.value);
                },
                oninput({target: {value}}) {
                    if (!readonly) {
                        field.showError = false;
                        field.value = value;
                    }
                },
            }),
            (field.errorText) && m('p.-fg-danger', field.errorText));
    },
};



export const CreateRelyingPartyForm = {
    view({
        attrs: spa,
    }) {
        const { staged } = spa.viewModel.rps;

        return m('.RelyingPartyForm',
            (staged.error) &&
                m('.RelyingPartyForm__error', staged.error.message),
            m(LabelInputPair, {
                staged,
                field: staged.fields.displayName,
            }, 'Customer Display Name'),
            m(LabelInputPair, {
                staged,
                readonly: staged.rpExists,
                field: staged.fields.entityName,
            },
            m('p', 'Entity Name'),
            m('em', `This should be a DBA name, modified so that it is no more
            than 16 characters and uses only numbers or all-lowercase English
            letters. So if the Customer Display Name is "28 Widgets, LLC", put
            something like "28widgets".`)),
            m(LabelInputPair, {
                staged,
                readonly: staged.rpExists,
                field: staged.fields.supportEmail,
            }, 'Support Email Address'),
            m(LabelInputPair, {
                staged,
                multiline: true,
                field: staged.fields.admins,
            }, 'Admin emails (Enter one per line. Duplicates will be removed.)'),
            m(NotificationControlPanel, {
                notifyPrefs: staged.fields.notifyPrefs,
            }),
            m(LabelInputPair, {
                staged,
                multiline: true,
                field: staged.fields.recipients,
            }, 'Notification recipients (Enter one per line. Duplicates will be removed.)'),
            m('.RelyingPartyForm__controls',
                m(Button, {
                    flags: {
                        secondary: true,
                        danger: true,
                    },
                    onpress: () => router.go('/rps'),
                }, 'Cancel'),
                m(Button, {
                    flags: {
                        round: true,
                        primary: true,
                    },
                    disabled: !staged.maySubmit,
                    onpress: staged.submit,
                }, 'Confirm')
            ),
            (staged.submitting) && m(Overlay, m(Spinner)));
    },
};
