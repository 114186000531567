import { errors, m, router, spa } from '#/browser-framework';
import { TranscribedError } from '#/browser-framework/comps';
import { purecomp } from '#/browser-framework/vcomps';

import { LargeFormatLayout } from '#/ops-facing/views/Layouts';


export const UnauthorizedJunctionUser = errors.definition(null, {
    reason: 'junction-unauthorized',
    extra: {
        displaySummary: 'You are not authorized to use this app.',
        displayMessage: 'If this seems wrong, ask your supervisor for access.',
    },
});

export const CannotLoadRps = errors.definition(null, {
    reason: 'cannot-load-rps',
    extra: {
        displaySummary: 'Cannot load RPs.',
        displayMessage: 'This looks like a possible service outage. Try again in a few minutes.',
    },
});

export const RpNotFound = errors.definition(null, {
    reason: 'rp-not-found',
    extra: {
        displaySummary: 'RP not found.',
        displayMessage: 'Please refine your search criteria, and try again.',
    },
});

export const CannotLoadVerification = errors.definition(null, {
    reason: 'cannot-load-verification',
    extra: {
        displaySummary: 'Cannot load verification.',
        displayMessage: 'The verification is either complete, on a different environment, or cannot be accessed right now.',
    },
});

export const RecordSubmissionFailed = errors.definition(null, {
    reason: 'record-submission-failed',
    extra: {
        displaySummary: 'Submission failed.',
        displayMessage: 'Please let Engineering know that you could not finish this verification.',
    },
});

export const NotAnEntityName = errors.definition(null, {
    reason: 'not-entity-name',
});

export const NotFound = errors.definition(null, {
    reason: 'not-found',
});

const SpaErrorView = purecomp(() =>
    m(TranscribedError, { error: spa.error }));

export const errorController = () => ({
    showError: (error) => {
        spa.error = error;
        spa.setView(SpaErrorView, { layout: LargeFormatLayout });
    },
    goToErrorPage: (code) =>
        router.go(`/error/${code}`),
});
