import { m } from '#/browser-framework';

import { ConfirmDialog } from './ConfirmDialog';


export const TimeoutConfirmationDialog = {
    view({
        attrs,
        children,
    }) {
        return m(ConfirmDialog, {
            ...attrs,
            confirmText: 'TIMEOUT',
            continueButtonText: 'Timeout',
        },
        m('h2', 'Expire this request? This action cannot be undone.'),
        (attrs.error)
            ? m('p', attrs.error.message)
            : '',
        children);
    },
};
