import { m } from '#/browser-framework';
import { Collapsible, KeyValueSet } from '#/browser-framework/comps';
import { purecomp } from '#/browser-framework/vcomps';

export const RequestInfo = purecomp(({
    displayEmail = true,
    email,
    summary,
    description,
    notes,
    open,
    ontoggle = () => {},
}) => {
    const fields = [
        ['IDO Email', email || ''],
        ['Summary', summary || ''],
        ['Description', description || '']];

    if (notes) {
        fields.push(['Notes', notes]);
    }

    return m('.RequestInfo',
        m(Collapsible, {
            open: (open === undefined)
                ? true
                : open,
            label: displayEmail
                ? m('h3', 'Request Information: ', m('strong', `${email}`))
                : m('h3', 'Request Information'),
            ontoggle,
        }, m(KeyValueSet, {
            pairs: fields,
        })));
});
