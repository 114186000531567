import { m } from '#/browser-framework';
import { Named } from '#/browser-framework/comps';


export const SearchBar = {
    view({
        attrs: {
            value,
            oninput,
            placeholder = 'Search...',
        },
    }) {
        return m('.SearchBar',
            m('input', {
                value,
                placeholder,
                oninput,
            }),
            m(Named.Icon, { name: 'search' }));
    },
};
