import { debounce, router, spa } from '#/browser-framework';
import { junctionServiceClient } from '#/ops-facing/junctionService';
import { CannotLoadRps, RpNotFound } from '#/ops-facing/controllers/errorController';

const displayNameSort = (a, b) => {
    return a.displayName.localeCompare(b.displayName, {sensitivity: 'base'});
};


export const rpController = ({ staged }) => {
    const iface = {
        staged,
        list: [],
        sortRpsAscending: true,
        loading: false,
        searchText: '',
        searchError: undefined,
    };

    iface.filterRpConfigResponseData = (data) => {
        const lowercaseSearchText = iface.searchText.toLowerCase();
        const list = data
            .filter((datum) => (
                datum.displayName
                    .toLowerCase()
                    .startsWith(lowercaseSearchText)));
        return list.sort((a, b) => displayNameSort(a, b));
    };

    iface.setSortAscending = (v) => {
        iface.sortRpsAscending = v;
        iface.list = iface.sortRps(iface.list, iface.sortRpsAscending);
    };

    iface.focusRp = (entityName) => {
        junctionServiceClient.getRpInfo(entityName);
        router.go(`/rps/edit/${entityName}`);
    };

    iface.requestRps = async () => {
        delete iface.searchError;
        const queryText = iface.searchText;
        try {
            const data = await junctionServiceClient
                .getAllRps({ searchText: iface.searchText, ascending: iface.sortRpsAscending });
            iface.list = iface.searchText && iface.searchText === queryText
                ? iface.filterRpConfigResponseData(data, queryText)
                : [];

            if (!iface.list.length) {
                iface.searchError = RpNotFound();
            }
        } catch (e) {
            iface.list = [];
            iface.searchError = CannotLoadRps();
        }

        iface.loading = false;
        spa.redraw();
    };

    iface.debouncedRequestRps = debounce(() => iface.requestRps(), 300);
    /**
     * sorts a list of rps in place by displayname and independent of case
     * @param {Array<object>} rps an array of rp configuration data
     * @param {boolean} isAscending true for ascending, false for descending
     */
    iface.sortRps = (rps, isAscending) => (
        rps.sort((a, b) =>
            (isAscending
                ? displayNameSort(a, b)
                : displayNameSort(b, a))));

    return iface;
};
