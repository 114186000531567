import { strings } from '#/browser-framework';

import DocumentCarousel from './DocumentCarousel';
import JsonDump from './JsonDump';


export function constructorFromType(dataType) {
    return ({
        'CapturedDocument': DocumentCarousel,
    })[dataType];
}

export function selectConstructor(spec) {
    try {
        return constructorFromType(spec.content.$objectType) || JsonDump;
    } catch (e) {
        return JsonDump;
    }
}

function validatePlugin(plugin) {
    if (typeof plugin.view !== 'object') {
        throw new Error('Ido attribute plugin does not have expected shape.');
    }

    return plugin;
}

function makeNewIdoAttrPlugin(spec) {
    const plugin = {
        attributeType: spec.attributeType,
        value: null,
        content: spec.content,
        labelText: spec.attributeType.split('.').map((s) => strings.capitalizeWord(s)).join(' '),
    };

    return plugin;
}

export function idoAttributeDisplayFactory(metadata) {
    const ctor = selectConstructor(metadata);

    if (ctor) {
        return validatePlugin(ctor(makeNewIdoAttrPlugin(metadata), metadata, idoAttributeDisplayFactory));
    } else {
        throw new Error(`No constructor for metadata: ${JSON.stringify(metadata)}`);
    }
}
