import { m } from '#/browser-framework';
import { naiveTitleCase } from '#/universal-framework/strings';
import { CountryStateDropdowns, US_GEONAME_DATA } from '#/ops-facing/fields/CountryStateDropdowns';
/**
 * @type Array
 */
import US_SUBDIVISIONS from '#/ido-lib/states.json';

const FIELD_NAMES = [
    'street_number',
    'street_name',
    'street_unit',
    'city',
    'postal_code',
    'postal_code_suffix',
    'neighborhood',
    'county',
    'post_office_box_id',
    'country',
    'state',
];

const DROPDOWN_FIELDS = [
    'country',
    'state',
];

export const MINIMUM_REQUIRED_FIELDS = [
    'city',
    'postal_code',
    'street_name',
    'street_number',
    'state',
    'country',
];

const MINIMUM_REQUIRED_LOOKUP = new Set(MINIMUM_REQUIRED_FIELDS);

export const ExplodedAddressForm = {
    view({
        attrs: {
            f,
        },
    }) {
        const isRequired = !f.metadata.optional;

        return m('.ExplodedAddressForm',
            [...((isRequired === true)
                ? FIELD_NAMES.filter((field) => (
                    !DROPDOWN_FIELDS.includes(field)))
                : FIELD_NAMES).map((name, key) =>
                m('label', { key, 'class': name },
                    m('div',
                        f.metadata.fieldStates[name].labelText,
                        (!f.metadata.optional && MINIMUM_REQUIRED_LOOKUP.has(name))
                            ? ' *'
                            : ''),
                    m('input', {
                        value: f.value[name],
                        name,
                        'class': (f.metadata.fieldStates[name].invalid)
                            ? 'border-danger'
                            : '',
                        oninput(e) {
                            f.value[name] = e.target.value;
                        },
                        onblur(e) {
                            f.metadata.fieldStates[name].invalid = (f.metadata.optional)
                                ? false
                                : MINIMUM_REQUIRED_LOOKUP.has(name) && !e.target.value;
                        },
                    })
                ))],
            (isRequired)
                ? m(CountryStateDropdowns, {
                    state: {
                        countryRequired: !f.metadata.optional,
                        stateRequired: f.value.country === US_GEONAME_DATA.ISO3,
                        showStateField: true,
                        ...f,
                    },
                })
                : '');
    },
};

export default function ExplodedAddress(plugin) {
    const statesMap = US_SUBDIVISIONS
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
    plugin.view = ExplodedAddressForm;
    plugin.showError = false;
    plugin.value = {
        city: '',
        country: 'USA',
        county: '',
        neighborhood: '',
        post_office_box_id: '',
        postal_code: '',
        postal_code_suffix: '',
        state: '',
        street_name: '',
        street_number: '',
        street_unit: '',
    };

    plugin.updateValue = (field, newValue) => {
        if (field === 'country') {
            plugin.value.state = '';
        }

        if (plugin.value.country === US_GEONAME_DATA.ISO3 && field === 'state') {
            plugin.value[field] = statesMap[newValue];
        } else {
            plugin.value[field] = newValue;
        }
    };

    plugin.metadata.fieldStates = {};

    FIELD_NAMES.reduce((p, c) => Object.assign(p, {
        [c]: {
            invalid: false,
            labelText: naiveTitleCase(c.replace(/_/g, ' ')),
        },
    }), plugin.metadata.fieldStates);

    plugin.metadata.fieldStates.street_unit.labelText = 'Unit/Apt.';

    plugin.isBlank = () =>
        Object
            .keys(plugin.value)
            .every((k) => plugin.value[k].length === 0);

    plugin.isValidEnough = () =>
        MINIMUM_REQUIRED_FIELDS.every((k) => plugin.value[k].length > 0);

    plugin.maySubmit = () =>
        ((plugin.metadata.optional)
            ? true
            : plugin.isValidEnough());

    plugin.encode = () =>
        Object.assign({ $objectType: 'Address' }, plugin.value);

    return plugin;
}
