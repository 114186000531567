import { m, tx } from '#/browser-framework';

export const SingleLineTextInputView = {
    view({
        attrs: {
            f,
        },
        children,
    }) {
        const id = f.metadata.memberName;
        const kids = (children.length > 0)
            ? children
            : [m('input', {
                id,
                placeholder: f.placeholder,
                value: f.value,
                onblur() {
                    f.showError = true;
                },
                oninput(e) {
                    f.showError = false;

                    f.value = e.target.value;
                },
            })];

        const showErrorFeedback = f.showError && !f.maySubmit();

        const classed = tx('.SingleLineTextInput', {
            'fg-danger': showErrorFeedback,
        });

        return m(classed,
            m('label', { for: id }, f.labelText),
            ...kids,
            (f.showError && f.errorText) && m('p.fg-danger', f.errorText));
    },
};

export default function SingleLineTextInput(plugin) {
    plugin.view = SingleLineTextInputView;
    plugin.showError = false;
    plugin.errorText = '';

    return plugin;
}
