import { contract, signature, type } from '#/universal-framework/functions';
import { events, spa } from '#/browser-framework';
import { reportError, submitRecord } from '#/ops-facing/junctionService';

import { GeneratedInterfaceModel } from './GeneratedInterface';
import JurisdictionMappingInterface from './JurisdictionMappingInterface';
import CheckIdInterface from './CheckIdInterface';
import CivilRecordInterface from './CivilRecordInterface';
import WatchListInterface from './WatchListInterface';

const MAPPING = {
    'ManualJurisdictionResult': JurisdictionMappingInterface,
    'ManualVerificationRecord': CheckIdInterface,
    'CivilProfileRecord': CivilRecordInterface,
    'GenericWatchListRecord': WatchListInterface,
};

const RECORD_PLUGIN_TYPE = type(({
    func,
    shape,
}) => shape({
    maySubmit: func,
    submit: func,
    view: shape({ view: func }),
    encode: func,
}, { allowExtraKeys: true }));

export const SUBMISSION_FAILED_MSG = 'Record submission failed';
export const SUBMISSION_SUCCESS_MSG = 'Record submission success';
export const normalizeMethods = (iface, recordSpec, recordData) => {
    const { recordType, authorizationId } = recordSpec;

    iface.recordType = recordType;
    iface.submit = async () => {
        if (iface.maySubmit()) {
            iface.submitting = true;
            spa.redraw();
            try {
                await submitRecord(authorizationId, iface.encode());
                events.emit('record-submitted', recordSpec, recordData);
                spa.viewModel.snackbar.display(SUBMISSION_SUCCESS_MSG, true);
            } catch (e) {
                spa.viewModel.snackbar.display(SUBMISSION_FAILED_MSG, false);
            }
            iface.submitting = false;
        } else {
            const message = `${recordType} is not a ${recordData.dataType}`;
            throw new Error(message);
        }
        spa.redraw();
    };
    iface.bail = async () => {
        await reportError(authorizationId, 'error-IdentityResolution');
        events.emit('record-submitted');
        iface.submitting = false;
        spa.redraw();
    };
    return iface;
};


export const recordInterfaceFactory = contract(
    (verification, recordSpec, recordData, rest = []) =>
        // TODO - overcomplicated implementation of polymorphism.
        // Consider refactoring to ES6/TypeScript class.
        normalizeMethods(
            (MAPPING[recordData.dataType] || GeneratedInterfaceModel)(verification, recordSpec, recordData, rest),
            recordSpec,
            recordData),
    signature(({ arrayOf, object, string }) => ({
        domain: [object, object, object, arrayOf(string).optional],
        range: RECORD_PLUGIN_TYPE,
        displayName: 'OpsRecordPlugin',
    })));
