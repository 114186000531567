import { timestampObjectToLocaleString } from '#/universal-framework/dates';


export function fulfilmentStatusStringFromAsa(asa) {
    return (asa.status === 'relyingparty-initiated')
        ? 'N/A'
        : (['new', 'pending'].includes(asa.fulfilment.status))
            ? (asa.fulfilment.next_check === null || asa.fulfilment.next_check === 0)
                ? 'Immediate'
                : timestampObjectToLocaleString(asa.fulfilment.next_check, 'Not scheduled')
            : 'N/A';
}
