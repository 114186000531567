import { bem, m } from '#/browser-framework';
import { Toggle } from '#/browser-framework/comps';


const NOTIFICATION_CONFIGS = [
    {
        name: 'Notify RP of completed requests',
        configName: 'rpCompleteRequest',
    },
    {
        name: 'Notify RP of datum shared',
        configName: 'rpDatumShare',
    },
    {
        name: 'Notify IDO of any new request',
        configName: 'idoNewRequest',
    },
    {
        name: 'Notify IDO of additional attributes required',
        configName: 'idoAddlAttrs',
    },
    {
        name: 'Notify IDO when they need to review results before sharing',
        configName: 'idoReadyToShare',
    },
];

const NotificationConfigTable = {
    view({
        attrs: {
            notifyPrefs,
        },
    }) {
        return m('table.NotificationConfigTable',
            m('tbody',
                m('tr',
                    m('th', ''),
                    m('th', 'Enable notification'),
                    m('th', 'Enable reminders')),
                ...NOTIFICATION_CONFIGS.map(({ name, configName }, key) => {
                    return m('tr.NotificationConfigItems', { key },
                        m('td', name),
                        m('td', m(Toggle, {
                            id: `enabled-${configName}`,
                            checked: notifyPrefs.value.enabled[configName],
                            onchange(state) {
                                notifyPrefs.value.enabled[configName] = state;

                                if (!state) {
                                    notifyPrefs.value.reminder[configName] = state;
                                }
                            },
                        })),
                        m('td', m(Toggle, {
                            id: `enabled-${configName}`,
                            disabled: !notifyPrefs.value.enabled[configName],
                            checked: notifyPrefs.value.reminder[configName],
                            onchange(state) {
                                notifyPrefs.value.reminder[configName] = state;
                            },
                        })));
                })
            ));
    },
};

export const NotificationControlPanel = {
    meronomy: bem`NotificationControlPanel
        instructions`,
    view({
        attrs: {
            notifyPrefs,
        },
    }) {
        const { block, instructions } = NotificationControlPanel.meronomy;

        return m(block,
            m(`p${instructions}`, `
            Use this to control what notifications go out from this account.`),
            m(NotificationConfigTable, {
                notifyPrefs,
            }));
    },
};
