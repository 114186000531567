import { m } from '#/browser-framework';


export const RequestSummary = {
    view({
        attrs: {
            rprId,
            rpDisplayName,
            createdAtText,
            summaryText,
            descriptionText,
            notesText,
        },
    }) {
        return m('.RequestSummary',
            m('table',
                m('tbody',
                    m('tr',
                        m('th', 'ID:'),
                        m('td', rprId)),
                    m('tr',
                        m('th', 'RP:'),
                        m('td', rpDisplayName)),
                    m('tr',
                        m('th', 'Created at:'),
                        m('td', createdAtText)),
                    m('tr',
                        m('th', 'Summary:'),
                        m('td', summaryText)))),
            m('table',
                m('tbody',
                    m('tr',
                        m('th', 'Description:'),
                        m('td', descriptionText)),
                    m('tr',
                        m('th', 'Notes:'),
                        m('td', notesText)))));
    },
};
