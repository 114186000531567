import { m } from '#/browser-framework';

import { ConfirmDialog } from './ConfirmDialog';


export const MacroConfirmationDialog = {
    view({
        attrs,
    }) {
        const { macroName } = attrs;

        return m(ConfirmDialog,
            Object.assign({}, attrs, {
                confirmText: 'MACRO',
                continueButtonText: 'Just do it, magic box.',
            }),
            m('h2', `Really run ${macroName}? This action can NOT be undone.`),
            (attrs.error) && m('p', attrs.error.message));
    },
};
