import { bem, m } from '#/browser-framework';
import { purecomp } from '#/browser-framework/vcomps';
import { factory } from '#/universal-framework/objects';
import { logger } from '#/universal-framework';
import { Dropdown, KeyValueSet, Named, PaginatedView } from '#/browser-framework/comps';
import { RequestInfo } from '#/ops-facing/views/RequestInfo';

const watchListMV = bem`
    watchList
    leftColumn
    rightColumn
    formHeader
    checkbox
    body
    addMatchButton
    removeField
    matchDetailField
    matchList
    matchListHeader
    matchListForm
    matchListQuestion
    matchStatus`;

const IdoDetailsView = purecomp(
    /**
    * @typedef {Array<string>} keyValueArr an array with a length of 2, each element should either be a string or a vnode
    * @param {object} obj
    * @param {string} obj.label - describes the context of this component
    * @param {keyValueArr[]} obj.tableContent
    */
    ({ label, tableContent }) => {
        return m(watchListMV.body,
            m(`h3.title${watchListMV.formHeader}`, label),
            m(KeyValueSet, {
                pairs: tableContent,
            }));
    });


const WatchListDetailsView = purecomp(({
    label,
    watchLists,
    recordData,
    visiblePageIndex,
    nextPage,
    previousPage,
}) => {
/**
 * @param {string} json - stringified JSON
 */
    const newTabContent = function(json) {
        const preformattedNode = document.createElement('pre');
        preformattedNode.textContent = json;
        const tab = window.open('about:blank', '_blank');
        tab.document.body.appendChild(preformattedNode);
        tab.document.close();
        return tab;
    };

    if (watchLists) {
        return m(watchListMV.matchList,
            m(watchListMV.matchListHeader,
                m(`h3.title${watchListMV.formHeader}`, label),
                m('a.jsonButton', {
                    onclick: () => newTabContent(JSON.stringify(recordData.values, null, 2)),
                }, 'View JSON'),
                m(PaginatedView, {
                    pages: watchLists,
                    unit: 'Match',
                    activePageIndex: visiblePageIndex,
                    nextPage,
                    previousPage,
                    showBottomNav: false,
                })));
    } else {
        return [m('pre', JSON.stringify(recordData.values, null, 2))];
    }
});



const watchListSelectionView = purecomp(
/** Description of the function
    * @param {object} obj - param object
    * @param {Array<string>} obj.watchLists - an array of watchlist names
    * @param {object} obj.matchData - the object that holds form state
    * @param {object} obj.matchData.list - where watchlist hits are stored on JSON parse success
    * @param {Array<string>} obj.matchData.failoverList - where watchlist hits are stored on JSON parse failure
    */
    ({watchLists, matchData}) => {
        const hasNoDuplicates = watchLists.length === new Set(watchLists).size;
        if (hasNoDuplicates && watchLists[0]) {
            const pairs = watchLists.map((name) => ([[
                m('label', m(`input.${watchListMV.checkbox}`, {
                    type: 'checkbox',
                    onclick: (event) => {
                        matchData.list[name] = event.target.checked;
                    },
                }),
                matchData.list[name]
                    ? m(Named.Icon, { name: 'checked'})
                    : m(Named.Icon, { name: 'unchecked' }))], name]));
            return m('.watchList__updater',
                m(`h3.title${watchListMV.formHeader}`, 'Watch List Matches'),
                m(KeyValueSet, {
                    pairs,
                }));
        } else {
            return [m(`h3.title${watchListMV.formHeader}`, 'Watch List Matches'),
                m('fieldset.watchList__updater',
                    m(`button${watchListMV.addMatchButton}`, {
                        onclick() {
                            matchData.failoverList.push('');
                        },
                    }, 'Add Match'),
                    matchData.failoverList.map((value, index) => (
                        m('.failoverInput',
                            m('input', {
                                value: matchData.failoverList[index],
                                oninput(e) {
                                    matchData.failoverList[index] = e.target.value;
                                },
                            }),
                            m(`button${watchListMV.removeField}`, {
                                onclick() {
                                    matchData.failoverList.splice(index, 1);
                                    m.redraw();
                                },
                            }, m(Named.Icon, { name: 'x'}))))))];
        }
    });

const MatchListView = purecomp(({
    statusOptions,
    matchData,
}) => {
    const statusKeys = Object.keys(statusOptions);
    return m(`fieldset${watchListMV.matchStatus}`,
        m('.status',
            m('label.title', 'Status'),
            m(Dropdown, {
                options: statusKeys,
                select: (i, j) => {
                    matchData.status = statusOptions[statusKeys[j]];
                },
                placeholder: 'Select one',
            })
        ),
        m('.matchDeets',
            m('label.title', 'Match details'),
            m(`textarea${watchListMV.matchDetailField}`, {
                onkeyup: (e) => {
                    matchData.details = e.target.value;
                },
                placeholder: 'How did you arrive at this conclusion?',
            })));
});

const WatchListInterface = purecomp(({ record }) => {
    const {email, summary, description, notes} = record.requestInfo;
    const statusOptions = record.recordData.resolvedMembers[2].options;

    return m(watchListMV.block,
        m(watchListMV.leftColumn,
            m(`h3.title${watchListMV.formHeader}`, 'Match Status'),
            m(MatchListView, {
                statusOptions,
                matchData: record.matchData,
            }),
            m(watchListSelectionView, {
                matchData: record.matchData,
                watchLists: record.watchListState.map((watchList) => {
                    return (watchList.watchlist_details && watchList.watchlist_details.source_of_record) || watchList.source;
                }),
            })),
        m(watchListMV.rightColumn,
            m(RequestInfo, {
                email,
                summary,
                description,
                notes,
                displayEmail: false,
                open: record.isVisible,
                ontoggle: record.toggleVisibility,
            }),
            m(IdoDetailsView, {
                label: 'Identity Owner Details',
                tableContent: record.idoDetails,
            }),
            m(WatchListDetailsView, {
                label: 'Match Details',
                watchLists: record.watchLists,
                recordData: record.recordData,
                visiblePageIndex: record.visiblePageIndex,
                nextPage: record.nextPage,
                previousPage: record.previousPage,
            })
        ));
});


/**
 * @typedef {Object} Name
 * @property {string} first - a given name or forename
 * @property {string} middle - a middle name
 * @property {string} last - a surname
 * @param {Name} watchListName - the name found on the potential watchlist hit
 * @param {Name} idoName - the name of the ido
 */

export const getMatchScore = function(watchListName, idoName) {
    const nameMatchScore = Object.keys(idoName)
        .reduce((accumulator, key) => (
            (idoName[key] && idoName[key].toLowerCase()) === (watchListName[key] && watchListName[key].toLowerCase())
            && (watchListName[key] && idoName[key])
                ? accumulator + 1
                : accumulator), 0);

    if (nameMatchScore === 0) {
        return m('em', 'Cannot match names, please review name records');
    }
    return (nameMatchScore === 3)
        ? 'Exact'
        : 'Partial';
};

export const extractWatchlists = function(recordData, recordValues) {
    const watchListArr = recordData.watch_list_records
    || (recordData.watchlist_summary && recordData.watchlist_summary.records);

    try {
        return watchListArr.map((watchList) => {
            const watchListData = {
                name: {},
            };

            const objectType = recordData.$objectType;

            if (objectType.slice(0, 3) === 'TLO') {
                watchListData.name.first = watchList.name.FirstName;
                watchListData.name.middle = watchList.name.MiddleName;
                watchListData.name.last = watchList.name.LastName;
                watchListData.dob = watchList.date_of_birth;
                watchListData.state = watchList.address.State;
                watchListData.origin = watchList.source;
            } else {
                watchListData.name.first = watchList.watchlist_details.name.first;
                watchListData.name.middle = watchList.watchlist_details.name.middle;
                watchListData.name.last = watchList.watchlist_details.name.last;
                watchListData.dob = watchList.watchlist_details.date_of_birth;
                watchListData.state = watchList.watchlist_details.state;
                watchListData.origin = watchList.watchlist_details.source_of_record;
            }
            const { name: {
                first,
                middle,
                last,
            },
            state,
            origin,
            } = watchListData;
            const matchScore = getMatchScore(watchListData.name, recordValues['core.fullname'].content);
            let { dob } = watchListData;

            // a string is the expected input type
            if (dob && typeof dob !== 'string') {
                // otherwise, attempt to read the objectType property
                if (dob.objectType === 'EDTFObject') {
                    dob = dob.edtf_string;
                // if no expected property exists, make the dob false
                } else {
                    dob = false;
                }
            }

            return m(KeyValueSet, {
                pairs: [
                    ['Watchlist', origin || m('em', 'Watch list origin not found')],
                    ['Match type', matchScore || m('em', 'Watch list score not found')],
                    ['State', state || m('em', 'State not found')],
                    ['First name', first || m('em', 'First name not found')],
                    ['Middle name', middle || m('em', 'Middle name not found')],
                    ['Last name', last || m('em', 'Last name not found')],
                    ['Date of Birth', dob || m('em', 'Date of Birth not found')],
                ],
            });
        });
    } catch (e) {
        logger.error('JSON schema issue, contact support', e);
        return null;
    }
};


export const extractIdoDetail = function(recordValues) {
    const { 'core.fullname': { content: nameContent }} = recordValues;
    const {first, middle, last} = nameContent;
    let birthDateString;

    if (recordValues['core.dateofbirth']) {
        const birthDateContent = recordValues['core.dateofbirth'].content;
        const {day, month, year } = birthDateContent;
        birthDateString = `${month}/${day}/${year}`;
    }

    let addressData;
    if (recordValues['core.address.fulladdress']) {
        addressData = recordValues['core.address.fulladdress'].content;
    }
    return [
        ['First name', first],
        ['Middle name', middle ? middle : m('em', 'Middle name not found')],
        ['Last name', last],
        ['Date of Birth', birthDateString || m('em', 'Date of Birth not found')],
        ['Address', addressData
            ? `${addressData.street_number} ${addressData.street_name}, ${addressData.city}, ${addressData.state} ${addressData.postal_code}`
            : m('em', 'Address Not Found')],
    ];
};



export default (verification, recordSpec, recordData) => {
    const recordValues = {};
    recordData.values.forEach((value) => {
        recordValues[value.attributeType] = value;
    });
    return factory((iface) => ({
        // TODO: confirm that the enum is returned the same way across all UIs

        view: WatchListInterface,
        requestInfo: {
            email: recordSpec.email,
            summary: recordSpec.summary,
            description: recordSpec.description,
            displayEmail: false,
        },
        idoDetails: extractIdoDetail(recordValues),
        watchLists: extractWatchlists(recordData.values[0].content, recordValues),
        watchListState: recordData.values[0].content.$objectType.slice(0, 3) === 'TLO'
            ? recordData.values[0].content.watch_list_records
            : recordData.values[0].content.watchlist_summary.records,
        submitted: false,
        isVisible: false,
        visiblePageIndex: 0,
        matchData: {
            status: null,
            details: '',
            list: {},
            failoverList: [],
        },
        recordData,
        toggleVisibility: () => {
            iface.isVisible = !iface.isVisible;
        },
        nextPage() {
            if (iface.visiblePageIndex < iface.watchListState.length) {
                iface.visiblePageIndex ++;
            } else {
                iface.visiblePageIndex = 0;
            }
        },
        previousPage() {
            if (iface.visiblePageIndex > 0) {
                iface.visiblePageIndex--;
            } else {
                iface.visiblePageIndex = iface.watchListState.length - 1;
            }
        },
        maySubmit: () => {
            const matchListArr = iface.matchData.failoverList.length
                ? iface.matchData.failoverList
                : Object.keys(iface.matchData.list)
                    .filter((watchlist) => iface.matchData.list[watchlist]);

            return (typeof iface.matchData.status === 'boolean') &&
                iface.matchData.details.length &&
                (Boolean(matchListArr.length) === iface.matchData.status);
        },
        /**
         * @typedef {Object} outboundPayload
         * @property {string} $objectType - describes the payload to the backend
         * @property {string} match_details - an answer to "why did the question get answered this way?"
         * @property {Array.<string>} match_jurisdictions - a list of all watchlists / jurisdictions that were considered "hits"
         * @property {boolean} match_summary - answers the question "Are there hits?"
         * @returns {outboundPayload}
         */
        encode: () => {
            return {
                $objectType: recordData.dataType,
                match_details: iface.matchData.details,
                match_summary: iface.matchData.status,
                match_lists: iface.matchData.failoverList.length
                    ? iface.matchData.failoverList
                    : Object.keys(iface.matchData.list)
                        .filter((watchlist) => iface.matchData.list[watchlist]),
            };
        },
    }));
};
