import { m, spa } from '#/browser-framework';
import { CapturedDocument } from '#/evident-attributes/views';


// This looks like crap because it's a shimmed adapter
// between ops-facing logic and a reusable component.
export default (plugin) =>
    Object.assign(CapturedDocument.makeViewModel({
        $window: spa.$window,
        value: plugin.content,
    }), plugin, {
        view: {
            view: ({ attrs: { a } }) =>
                m(CapturedDocument, a),
        },
    });
