/* eslint-disable max-lines */
/* eslint-disable prefer-template */
import moment from 'moment';
import {
    Button,
    Collapsible,
    Named,
    Scene,
    Spinner,
    Overlay,
} from '#/browser-framework/comps';
import { bem, events, logger, m, router, spa, tx } from '#/browser-framework';
import { createDataUrlFromBinaryData, createBinaryDataFromDataUrl } from '#/evident-attributes/encoding/documents';
import { draw2dImage, preloadImage, srt2dPipeline } from '#/browser-framework/canvases';
import { extrapolateCustomerIdScanPrescriptions, ID_DOCUMENT_TYPE_TAXONS } from '#/evident-attributes/attrTypes';
import { factory } from '#/universal-framework/objects';
import { letc } from '#/universal-framework/functions';
import { modulo, toDegrees, toRadians } from '#/universal-framework/numbers';
import { naiveTitleCase, upper } from '#/universal-framework/strings';
import { purecomp } from '#/browser-framework/vcomps';

import { CountryStateDropdowns, US_GEONAME_DATA } from '#/ops-facing/fields/CountryStateDropdowns';

import { attributeFieldFactory } from '#/ops-facing/fields';
import { getManualVerificationDetails } from '#/ops-facing/junctionService';
import { RequestInfo } from '#/ops-facing/views/RequestInfo';
import { startListening } from '#/ops-facing/pdf417BackChannel';
import { Switch } from 'mithril-materialized';

/**
 * @type Array
 */
import US_SUBDIVISIONS from '#/ido-lib/states.json';
import { AcuantErrorsComponent } from '#/ops-facing/records/AcuantErrorsDisplay/AcuantErrorsComponent';
import { prepareListOfAlerts } from '#/ops-facing/records/AcuantErrorsDisplay/AcuantAlertsProcessing';


const FRAME_BUFFER_W = 1280;
const FRAME_BUFFER_H = 720;
const FRAME_BG_COLOR = '#f4f4f4';
const ADJ_PROP_PREVIEW_ID = 'adj-preview';
const FACE_CROP_PREVIEW_ID = 'face-crop-preview';

const _drawAdjustedId = (ctx, {
    brightness,
    contrast,
    height,
    imageNode,
    radians,
    xTranslate,
    yTranslate,
    width,
    xScale,
    yScale,
}) => {
    ctx.fillStyle = FRAME_BG_COLOR;
    ctx.fillRect(0, 0, width, height);
    ctx.filter = `brightness(${brightness}%) contrast(${contrast}%)`;

    srt2dPipeline(ctx, {
        height,
        radians,
        width,
        xTranslate,
        yTranslate,
        xScale,
        yScale,
    });

    if (imageNode) {
        draw2dImage(ctx, imageNode, {
            dw: width,
            dh: height,
        });
    }
};

const _fieldVNode = (record, name) =>
    m(record.fields[name].view, { f: record.fields[name]});

const _mountPanzoom = ({ dom }) =>
    import('panzoom').then(({ default: panzoom }) => {
        panzoom(dom.children[0], {
            bounds: true,
            boundsPadding: 1,
            boundsDisabledForZoom: true,
            smoothScroll: false,
            minZoom: 0.8,
        });
    });

const _mountCropper = (id) => ({ attrs: { record } }) => {
    const node = spa.$window.document.getElementById(id);

    if (record.Cropper && spa.$window.document.getElementById(id)) {
        if (node.nodeName.toLowerCase() === 'img') {
            node.addEventListener('load', () => {
                events.emit('croppable-image-mounted', id);
            });

            node.src = node.src;
        } else {
            events.emit('croppable-image-mounted', id);
        }
    }
};

const _dateFieldToMoment = (dateField) => {
    // null configs create invalid moment objects
    let momentConfig = null;

    try {
        const encodedDate = dateField.encode();
        momentConfig = {
            year: encodedDate.year,
            month: encodedDate.month - 1,
            day: encodedDate.day,
        };
    } catch (invalidDateError) {
        // Errors result in null momentConfig
    }

    return moment(momentConfig);
};

const DocumentImageSmallNav = letc([bem`DocumentImageSmallNav prev label next`],
    ([{ block, prev, label, next }]) =>
        purecomp(({ selectedOrdinal, total }) =>
            m(block,
                m(prev, {
                    onclick: () =>
                        events.emit('select-prev-image'),
                }, m(Named.Icon, { name: 'chevron-left' })),
                m(label,
                    `${selectedOrdinal + 1} of ${total}`),
                m(next, {
                    onclick: () =>
                        events.emit('select-next-image'),
                }, m(Named.Icon, { name: 'chevron-right' })))));

const IdoDetails = purecomp(({ open, record }) =>
    m(Collapsible, {
        open,
        label: 'IDO Details',
        ontoggle: (prevState) => events.emit('toggle-ido-details-form', prevState),
    }, m('.IdDocumentIdoDetailsForm',
        _fieldVNode(record, 'full_name'),
        _fieldVNode(record, 'date_of_birth'),
        _fieldVNode(record, 'sex'))));

const AddressDetails = purecomp(({ open, record }) =>
    m(Collapsible, {
        open,
        label: 'Address Details',
        ontoggle: (prevState) => events.emit('toggle-address-form', prevState),
    }, _fieldVNode(record, 'address')));

const ExpirationDateField = (record) =>
    m('.ExpirationDate',
        m(Named.Icon, {
            name: 'info-circle',
            tooltip: 'Does the Identity Document have an expiration date?',
        }),
        m(Switch, {
            alt: 'Does the Identity Document have an expiration date?',
            label: 'Has Expiration Date?',
            right: 'YES',
            left: 'NO',
            checked: !record.fields.expiration_date.nonExpiring,
            onchange() {
                const {expiration_date: expirationDate } = record.fields;
                const isExpirationDateOptional = (
                    record.fields.status.country === 'IND' ||
                    record.fields.status.country === 'COL' ||
                    (record.fields.status.country === 'USA' && record.fields.province.value === 'AZ'));

                if (isExpirationDateOptional) {
                    expirationDate.toggleNonExpiringValue();
                    if (record.fields.expiration_date.nonExpiring) {
                        expirationDate.metadata.readOnly = false;
                        expirationDate.metadata.includesDate = false;
                        expirationDate.value = null;
                    } else {
                        expirationDate.metadata.readOnly = true;
                        expirationDate.metadata.includesDate = true;
                        expirationDate.value = null;
                    }
                } else {
                    expirationDate.metadata.includesDate = true;
                    expirationDate.nonExpiring = false;
                    expirationDate.metadata.readOnly = false;
                    expirationDate.value = null;
                }
            },
        }),
        _fieldVNode(record, 'expiration_date'));


export const updateCountryStateDropdown = function(field, newValue, fields) {
    if (field === 'country') {
        fields.country.value = newValue;
        fields.status.country = newValue;
        if (newValue === US_GEONAME_DATA.ISO3) {
            fields.province.value = null;
        } else {
            fields.province.value = ' ';
        }
    }
    if (field === 'state') {
        if (fields.status.country === US_GEONAME_DATA.ISO3) {
            const statesObj = {};
            US_SUBDIVISIONS.forEach(([key, value]) => {
                statesObj[key] = value;
            });
            fields.province.value = statesObj[newValue];
        } else {
            fields.province.value = newValue;
        }
    }
};

export const DocDetails = purecomp(({ open, record }) =>
    m(Collapsible, {
        open,
        label: 'Document Details',
        ontoggle: (prevState) => events.emit('toggle-doc-details-form', prevState),
    }, m('.IdDocumentIssuanceDetailsForm',
        _fieldVNode(record, 'document_number'),
        (record.selectedDocumentType === 'drivers_license') && _fieldVNode(record, 'licenseclass'),
        _fieldVNode(record, 'issue_date'),
        _fieldVNode(record, 'status'),
        m(CountryStateDropdowns, {
            oninit: () => {
                record.fields.country.value = US_GEONAME_DATA.ISO3;
                record.fields.status.country = US_GEONAME_DATA.ISO3;
            },
            state: {
                countryRequired: !record.fields.country.metadata.optional,
                showStateField: (record.selectedDocumentType !== 'passport'),
                stateRequired: record.fields.province.metadata.optional,
                country: record.fields.status.country,
                state: record.fields.status.state,
                record,
                /**
                 * @param {string} field - 'country' || 'state'
                 * @param {string} newValue - ISO3 value or administrative subdivision name
                 */
                updateValue: (field, newValue) => {
                    record.fields.expiration_date.nonExpiring = false;
                    updateCountryStateDropdown(field, newValue, record.fields);
                },
            },
        }),
        ExpirationDateField(record),
    )));

const IdDocumentImageScene = letc([bem`IdDocumentImageScene`],
    ([{ block }]) =>
        purecomp(({ id, img, width = FRAME_BUFFER_W, height = FRAME_BUFFER_H }) =>
            m(block,
                m(Scene, {
                    id,
                    sceneVars: {
                        imageNode: img.imageNode,
                        brightness: img.brightness,
                        contrast: img.contrast,
                        yScale: img.yScale * img.scalar,
                        xScale: img.xScale * img.scalar,
                        yTranslate: img.yTranslate,
                        xTranslate: img.xTranslate,
                        radians: img.radians,
                        width,
                        height,
                    },
                    contextAttributes: {
                        alpha: false,
                    },
                    draw: _drawAdjustedId,
                    width,
                    height,
                }))));

// eslint-disable-next-line no-unused-vars
const DocumentTypeAssertion = letc([bem`DocumentTypeAssertion question choicesContainer`],
    ([{ block, question, choicesContainer }]) =>
        purecomp(({ record: { customerDocTypeLabels } }) =>
            ((customerDocTypeLabels)
                ? m(block,
                    m(question,
                        m('h2',
                            'Click the button that represents the type of document that you see on the right'),
                        m('p',
                            'If the document does not match one of the buttons highlighted in green, ',
                            m.trust('click &ldquo;None of the above&rdquo;'))),
                    m(choicesContainer,
                        customerDocTypeLabels
                            .concat([[undefined, 'None of the above']])
                            .map(([code, label], key) =>
                                m(Button, {
                                    key,
                                    flags: {
                                        [(code) ? 'primary' : 'danger']: true,
                                    },
                                    onpress: () => events.emit('assert-id-document-type', code),
                                }, label))))
                : m(block, m(Spinner)))));

const getBaseIDVerificationForm = (record, cssClass, shouldShowAddress = true) => {
    return m(cssClass,
        m(AcuantErrorsComponent, {
            open: record.showVerificationFailures,
            record,
        }),
        m(IdoDetails, {
            open: record.showIdoDetailsForm,
            record,
        }),
        shouldShowAddress
            ? m(AddressDetails, {
                open: record.showAddressForm,
                record,
            })
            : null,
        m(DocDetails, {
            open: record.showDocDetailsForm,
            record,
        }));
};

const CheckIdCardForms = purecomp(({ record }) => getBaseIDVerificationForm(record, 'CheckIdCardForms'));
const CheckDriversLicenseForms = purecomp(({ record }) => getBaseIDVerificationForm(record, 'CheckDriversLicenseForms'));
const CheckPassportForms = purecomp(({ record }) => getBaseIDVerificationForm(record, 'CheckPassportForms', false));

const IdDocumentImageAdjustmentDialogFilterControls = (
    letc([bem`IdDocumentImageAdjustmentDialogFilterControls sliderContainer`],
        ([{ block, sliderContainer }]) =>
            purecomp(({ brightness, contrast }) =>
                m(block,
                    m(sliderContainer,
                        m('p', 'Brightness'),
                        m('input', {
                            type: 'range',
                            min: 0,
                            max: 200,
                            step: 1,
                            value: brightness,
                            oninput: (e) => events.emit('new-brightness-value', e.target.value),
                        })),
                    m(sliderContainer,
                        m('p', 'Contrast'),
                        m('input', {
                            type: 'range',
                            min: 0,
                            max: 200,
                            step: 1,
                            value: contrast,
                            oninput: (e) => events.emit('new-contrast-value', e.target.value),
                        }))))));



const IdDocumentImageAdjustmentDialogTransformControls = (
    letc([bem`IdDocumentImageAdjustmentDialogTransformControls rotations flips slider`],
        ([{ block, rotations, flips, slider }]) =>
            purecomp(({ radians, scalar, xTranslate, yTranslate }) =>
                m(block,
                    m(rotations,
                        m(Button, {
                            onpress: () =>
                                events.emit(
                                    'new-image-angle',
                                    modulo(toDegrees(radians + Math.PI / 2), 360) - 180),
                        }, m(Named.Icon, { name: 'rotate-left' })),
                        m(Button, {
                            onpress: () =>
                                events.emit(
                                    'new-image-angle',
                                    modulo(toDegrees(radians - Math.PI / 2), 360) - 180),
                        }, m(Named.Icon, { name: 'rotate-right' }))),
                    m(flips,
                        m(Button, {
                            onpress: () => events.emit('flip-horizontally'),
                        }, m(Named.Icon, { name: 'flip-h' })),
                        m(Button, {
                            onpress: () => events.emit('flip-vertically'),
                        }, m(Named.Icon, { name: 'flip-v' }))),
                    m(slider,
                        m('p', `Rotation (${Math.floor(toDegrees(radians))} degrees)`),
                        m('input', {
                            type: 'range',
                            min: -180,
                            max: 180,
                            step: 1,
                            value: toDegrees(radians),
                            id: 'image-rotation',
                            oninput: (e) => events.emit('new-image-angle', e.target.value),
                        }),
                        m('p', `${scalar.toPrecision(2)}x of original size`),
                        m('input', {
                            type: 'range',
                            min: 0.05,
                            max: 5,
                            step: 0.05,
                            value: scalar,
                            id: 'image-scale',
                            oninput: (e) => events.emit('new-image-scale', e.target.value),
                        }),
                        m('p', `Horizontal offset (${xTranslate} pixels)`),
                        m('input', {
                            type: 'range',
                            min: -(FRAME_BUFFER_W / 2),
                            max: (FRAME_BUFFER_W / 2),
                            step: 10,
                            value: xTranslate,
                            id: 'image-x-off',
                            oninput: (e) => events.emit('new-image-x-off', e.target.value),
                        }),
                        m('p', `Vertical offset (${yTranslate} pixels)`),
                        m('input', {
                            type: 'range',
                            min: -(FRAME_BUFFER_H / 2),
                            max: (FRAME_BUFFER_H / 2),
                            step: 10,
                            value: yTranslate,
                            id: 'image-y-off',
                            oninput: (e) => events.emit('new-image-y-off', e.target.value),
                        }))))));

const IdDocumentImageAdjustmentDialogCropControls = (
    letc([bem`IdDocumentImageAdjustmentDialogCropControls notice actionContainer`, _mountCropper(ADJ_PROP_PREVIEW_ID)],
        ([{ block, actionContainer, notice }, _mountAdjCrop]) =>
            ({
                oncreate: _mountAdjCrop,
                onupdate: _mountAdjCrop,
                onremove: ({ attrs: { record } }) => {
                    record.dirtyCropper();
                },
                view: () =>
                    m(block,
                        m(`p${notice}`,
                            'Drag the handles on the right to the desired ',
                            'boundaries for your image, then click the button ',
                            'below to apply the crop.'),
                        m(`p${notice}`,
                            m('strong',
                                'Beware that the crop will include any adjustments you apply. '),
                            'If you want to start over, use the Reset button.'),
                        m(actionContainer,
                            m(Button, {
                                flags: {
                                    primary: true,
                                },
                                onpress: () => events.emit('commit-crop'),
                            }, 'Apply crop'))),
            })));



const IdDocumentImageAdjustmentDialogToolbar = letc([bem`IdDocumentImageAdjustmentDialogToolbar navContainer actionContainer`],
    ([{ block, navContainer, actionContainer }]) =>
        purecomp(({ record }) =>
            m(block,
                m(navContainer,
                    m(DocumentImageSmallNav, {
                        total: record.selectableImages.length,
                        selectedOrdinal: record.selectedImageOrdinal,
                    })),
                letc([record.getSelectedImageRef()], ([ref]) =>
                    m(actionContainer,
                        m(Button, {
                            flags: {
                                danger: true,
                                round: true,
                            },
                            onpress: () => events.emit('reset-selected-adjustments'),
                        }, 'Reset'),
                        m(Button, {
                            flags: {
                                primary: true,
                                round: true,
                            },
                            disabled: !ref.src || !ref.dirty,
                            onpress: () => events.emit('save-selected-adjustments'),
                        }, 'Apply adjustments'),
                        m(Button, {
                            flags: {
                                secondary: true,
                                glass: true,
                            },
                            onpress: () => events.emit('close-edit-dialog'),
                        }, m(Named.Icon, { name: 'x' })))))));

const CollapsibleIdDocumentImageAdjustmentDialogMenu = (
    letc([bem`CollapsibleIdDocumentImageAdjustmentDialogMenu headingContainer iconContainer labelContainer`],
        ([{ block, headingContainer, iconContainer, labelContainer }]) =>
            purecomp(({ open, iconName, labelText, eventName }, children) =>
                m(block,
                    m(Collapsible, {
                        open,
                        label: m(headingContainer,
                            m(iconContainer, m(Named.Icon, { name: iconName })),
                            m(labelContainer, labelText)),
                        ontoggle: (state) => events.emit(eventName, state),
                        iconWhenOpen: '▴',
                        iconWhenClosed: '▾',
                    }, children)))));



const IdDocumentImageAdjustmentDialog = (
    letc([bem`IdDocumentImageAdjustmentDialog toolbarContainer adjustmentsContainer imageContainer`],
        ([{ block, toolbarContainer, adjustmentsContainer, imageContainer }]) =>
            purecomp(({ record }) =>
                letc([record.getSelectedImageRef()], ([imageRef]) =>
                    m(block,
                        m(toolbarContainer,
                            m(IdDocumentImageAdjustmentDialogToolbar, { record })),
                        m(adjustmentsContainer,
                            m(CollapsibleIdDocumentImageAdjustmentDialogMenu, {
                                open: record.openTransformMenu,
                                iconName: 'rotate-left',
                                labelText: 'Transform',
                                eventName: 'select-image-adjustment-dialog-transform-menu',
                            },
                            m(IdDocumentImageAdjustmentDialogTransformControls, {
                                radians: imageRef.radians,
                                scalar: imageRef.scalar,
                                xTranslate: imageRef.xTranslate,
                                yTranslate: imageRef.yTranslate,
                            })),
                            m(CollapsibleIdDocumentImageAdjustmentDialogMenu, {
                                open: record.openCropMenu,
                                iconName: 'crop',
                                labelText: 'Crop',
                                eventName: 'select-image-adjustment-dialog-crop-menu',
                            },
                            m(IdDocumentImageAdjustmentDialogCropControls, {
                                record,
                            })),
                            m(CollapsibleIdDocumentImageAdjustmentDialogMenu, {
                                open: record.openFilterMenu,
                                iconName: 'sliders',
                                labelText: 'Adjust',
                                eventName: 'select-image-adjustment-dialog-filters-menu',
                            },
                            m(IdDocumentImageAdjustmentDialogFilterControls, {
                                brightness: imageRef.brightness,
                                contrast: imageRef.contrast,
                            }))),
                        m(imageContainer,
                            (imageRef.src)
                                ? m(IdDocumentImageScene, {
                                    id: 'adj-preview',
                                    img: imageRef,
                                })
                                : m('p', 'There\'s no image to edit.')))))));


const IdDocumentFaceCroppingDialogToolbar = letc([bem`IdDocumentFaceCroppingDialogToolbar smallNavContainer actionContainer`],
    ([{ block, smallNavContainer, actionContainer }]) =>
        purecomp(({
            selectedImageOrdinal,
            selectableImages,
        }) =>
            m(block,
                m(smallNavContainer,
                    m(DocumentImageSmallNav, {
                        selectedOrdinal: selectedImageOrdinal,
                        total: selectableImages.length,
                    })),
                m(actionContainer,
                    m(Button, {
                        flags: {
                            secondary: true,
                            glass: true,
                        },
                        onpress: () => events.emit('cancel-face-crop'),
                    }, 'Cancel'),
                    m(Button, {
                        flags: {
                            primary: true,
                            round: true,
                        },
                        onpress: () => events.emit('apply-face-crop'),
                    }, 'Apply')))));

const IdDocumentFaceCroppingTarget =
    letc([_mountCropper(FACE_CROP_PREVIEW_ID)], ([_mountFaceCrop]) => ({
        oncreate: _mountFaceCrop,
        onupdate: _mountFaceCrop,
        onremove: ({ attrs: { record } }) => {
            record.dirtyCropper();
        },
        view: ({ attrs: { record } }) => {
            return m('img', {
                crossorigin: 'anonymous',
                id: FACE_CROP_PREVIEW_ID,
                src: record.getSelectedImageRef().src,
            });
        },
    }));

const IdDocumentFaceCroppingDialog = (
    letc([
        bem`IdDocumentFaceCroppingDialog toolbarContainer imageContainer controlsContainer workspace`,
    ],
    ([{ block, toolbarContainer, imageContainer, workspace }]) => ({
        view: ({ attrs: { record } }) =>
            letc([record.getSelectedImageRef()], ([imageRef]) =>
                ((record._loadingCropper)
                    ? m(block, m(Spinner))
                    : m(block,
                        m(toolbarContainer,
                            m(IdDocumentFaceCroppingDialogToolbar, {
                                selectableImages: record.selectableImages,
                                selectedImageOrdinal: record.selectedImageOrdinal,
                            })),
                        m(workspace,
                            m(imageContainer,
                                (imageRef.src)
                                    ? m(IdDocumentFaceCroppingTarget, { record })
                                    : m('p', 'There\'s nothing to crop.')))))),
    })));


const DocumentImageToolbar = letc([bem`DocumentImageToolbar smallNavContainer buttonContainer`],
    ([{ block, smallNavContainer, buttonContainer }]) =>
        purecomp(({
            selectedImageOrdinal,
            selectableImages,
        }) =>
            m(block,
                m(smallNavContainer,
                    m(DocumentImageSmallNav, {
                        selectedOrdinal: selectedImageOrdinal,
                        total: selectableImages.length,
                    })),
                m(buttonContainer,
                    m(Button, {
                        flags: {
                            primary: true,
                            glass: true,
                        },
                        onpress: () => events.emit('edit-selected-image'),
                    }, 'Edit')))));


const DocumentImageViewer = letc([bem`DocumentImageViewer`],
    ([{ block }]) => ({
        oncreate: _mountPanzoom,
        view: (({ attrs: { selectedImage } }) =>
            m(block, {
                style: {
                    overflow: 'hidden',
                },
            },
            m(IdDocumentImageScene, {
                id: 'selected-image',
                img: selectedImage,
            }))),
    }));

const DocumentImageOption = letc([bem`DocumentImageOption thumbnailContainer nameContainer actionContainer`],
    ([{ block, thumbnailContainer, nameContainer, actionContainer }]) =>
        purecomp(({ ordinal, selected, docImageData }) =>
            m(tx(block, {
                [`${block}--selected`]: selected,
                [`${block}--unavailable`]: !docImageData,
            }), { 'data-ordinal': ordinal },
            m(thumbnailContainer,
                m(IdDocumentImageScene, {
                    id: `thumb-${ordinal}`,
                    img: docImageData,
                    width: 96,
                    height: 53,
                })),
            m(nameContainer, { 'class': (docImageData.src) ? '' : 'fg-danger' }, docImageData.labelText),
            (docImageData.forFace) &&
                m(actionContainer,
                    m(Button, {
                        flags: {
                            primary: true,
                            glass: true,
                        },
                        onpress: () => events.emit('capture-missing-face', ordinal),
                    }, 'Capture')))));

const DocumentImageListing = letc([bem`DocumentImageListing itemContainer`],
    ([{ block, itemContainer }]) =>
        purecomp(({
            selectedImageOrdinal,
            selectableImages,
        }) =>
            m(block, {
                onclick: (e) =>
                    letc([e.target.closest('[data-ordinal]').getAttribute('data-ordinal')], ([imageOrdinal]) =>
                        ((imageOrdinal)
                            ? events.emit('select-image', imageOrdinal)
                            : null)),
            },
            selectableImages.map((docImageData, key) =>
                m(itemContainer, { key },
                    m(DocumentImageOption, {
                        selected: selectedImageOrdinal === key,
                        docImageData,
                        actions: [],
                        ordinal: key,
                    }))))));

/*
From http://scales.ascii.uk/

+------------------------------------------------------+
| < 1 of 3 >      [DocumentImageToolbar]         Edit  |
+------------------------------------------------------+
| [DocumentImageViewer]                                |
| http://scales.ascii.uk/                              |
|                                      .-===-.         |
|                                       \   /          |
|                                       |   |          |
|                                     __|:::|__        |
|        .-===-.                 _.--'  |:::|  `-._    |
|         \   /           __    /      (:::::)     \   |
|         |:::|          |  |   \       `---'      /   |
|       __|:::|__        |..|    ``--...____...--''    |
|  _.--'  |:::|  `-._   /_/\_\     ___..-(O/           |
| /      (:::::)     \  |  __...--' __..-''            |
| \       `---'      /_.--(o)_...--'                   |
|  ``--...____...--''__..--'_|                         |
|         \O)___..--'   \ \/ /                         |
|          .-------------|''|-------------.            |
|         /              |__|              \           |
|  LGB   /__________________________________\          |
|        '----------------------------------'          |
+------------------------------------------------------+
|                                                      |
| +--------------------------------------------------+ |
| | +-----+                                          | |
| | |  O  | Image 1                                  | |
| | +-----+                                          | |
| +--------------------------------------------------+ |
| | +-----+                                          | |
| | |  V  | Image 2                                  | |
| | +-----+                                          | |
| +--------------------------------------------------+ |
| | +-----+                                          | |
| | |  X  | Image 3                        Capture   | |
| | +-----+                                          | |
| +--------------------------------------------------+ |
+------------------------------------------------------+
*/
const DocumentImageNavigation = letc([bem`DocumentImageNavigation toolbarContainer viewerContainer listingContainer`],
    ([{ block, toolbarContainer, viewerContainer, listingContainer }]) =>
        purecomp(({
            selectedImageOrdinal,
            selectableImages,
        }) =>
            m(block,
                m(toolbarContainer,
                    m(DocumentImageToolbar, {
                        selectedImageOrdinal,
                        selectableImages,
                    })),
                m(viewerContainer,
                    m(DocumentImageViewer, {
                        selectedImage: selectableImages[selectedImageOrdinal],
                    })),
                m(listingContainer,
                    m(DocumentImageListing, {
                        selectedImageOrdinal,
                        selectableImages,
                    })))));


const CheckIdInterface = letc([bem`CheckIdInterface forms submittedImages`],
    ([{ block, forms, submittedImages }]) =>
        purecomp(({ record }) =>
            m(block,
                m(forms,
                    (record.fields)
                        ? m(({
                            'id_card': CheckIdCardForms,
                            'drivers_license': CheckDriversLicenseForms,
                            'passport': CheckPassportForms,
                        }[record.selectedDocumentType] || DocumentTypeAssertion), { record })
                        : m(Spinner)),
                m(submittedImages,
                    m(RequestInfo, Object.assign({
                        open: record.showRequestInfo,
                        ontoggle: () => events.emit('toggle-request-info'),
                    }, record.recordSpec)),
                    m('h3', 'Submitted Images'),
                    m(DocumentImageNavigation, {
                        selectableImages: record.selectableImages,
                        selectedImageOrdinal: record.selectedImageOrdinal,
                    })),
                (record.showImageAdjustmentDialog) &&
                    m(Overlay, { full: true },
                        m(IdDocumentImageAdjustmentDialog, { record })),
                (record.showFaceCroppingDialog) &&
                    m(Overlay, { full: true },
                        m(IdDocumentFaceCroppingDialog, { record })))));


const imageModel = (o) => {
    const out = Object.assign({
        labelText: 'Untitled',
        src: `https://via.placeholder.com/${FRAME_BUFFER_W}?text=Untitled`,
        isLocal: false,
        radians: 0,
        brightness: 100,
        contrast: 100,
        xTranslate: 0,
        yTranslate: 0,
        xScale: 1,
        yScale: 1,
        scalar: 1,
        cropped: false,
    }, o);

    out.updateSrc = (newsrc) => {
        out.imageNode = null;

        if (newsrc) {
            // React to base64 encodings with newlines in them.
            // Android release caused a need for this.
            const touse = (newsrc.indexOf('\n') > -1)
                ? newsrc.replace(/\n/g, '')
                : newsrc;

            out._preload =
                preloadImage(touse)
                    .then((img) => {
                        out.src = touse;
                        out.imageNode = img;
                    })
                    .catch((e) => {
                        out.error = e;
                    })
                    .then(() => {
                        delete out._preload;
                        spa.redraw();
                    });
        }
    };

    out.originalSrc = out.src;
    out.updateSrc(out.src);

    return out;
};

export const labelFromInputAttrType = (attributeType) => {
    if (attributeType.indexOf('.front') > -1) {
        return 'Front';
    } else if (attributeType.indexOf('.back') > -1) {
        return 'Back';
    } else if (attributeType.indexOf('.passport') > -1) {
        return 'Passport ID page';
    } else if (attributeType.indexOf('face') > -1) {
        return 'Face image from document';
    }

    return attributeType;
};

const modelsFromRecordData = (recordData) => {
    const binaryObjs = recordData
        .values
        .filter(({ content }) => (
            typeof content === 'object' &&
            content !== null &&
            content.$objectType === 'BinaryData'));

    // This will hold a subjective ordering of the data as prescribed by the ops team.
    const permuted = [];

    // The side-effect of modifying 'binaryObjs' is leveraged to place
    // extra images in a prescribed location.
    const pushIf = (f) => {
        const i = binaryObjs.findIndex(f);

        if (i !== -1) {
            permuted.push(binaryObjs.splice(i, 1).pop());
        }
    };

    // Closure to match on substrings in attr types
    const substrMatch = (s) => ({ attributeType }) => attributeType.indexOf(s) !== -1;

    // Notice that the associated images might not be present. But if they are,
    // they will appear in the order you are reading.
    pushIf(substrMatch('front'));
    pushIf(substrMatch('back'));
    pushIf(substrMatch('passport'));
    pushIf(substrMatch('face'));

    // Toss the rest at the end. The side-effect from pushIf
    // prevents duplicates.
    permuted.push(...binaryObjs);

    const models = permuted
        .map(({ attributeType, content }) =>
            imageModel({
                attributeType,
                forFace: attributeType.indexOf('face') > -1,
                labelText: labelFromInputAttrType(attributeType),
                src: createDataUrlFromBinaryData(content),
            }));

    // If no face image was provided, tack on a slot to provide one.
    return (models.find(({ forFace }) => forFace))
        ? models
        : models.concat([imageModel({
            forFace: true,
            attributeType: '',
            labelText: 'Missing face image',
            src: null,
        })]);
};

/*
Given a prefix string, a nullable document taxon prescription, and a mapping
of document taxons to labels, return an array of taxons and labels,
alphabetized by labels.

e.g.

prependDocLabelSelection('US ', null, { id_card: 'ID', drivers_license: 'DL' })
    -> [
        ['drivers_license', 'US DL'],
        ['id_card', 'US ID'],
    ]

prependDocLabelSelection('AF ', 'id_card', { id_card: 'ID', drivers_license: 'DL' })
    -> [
        ['id_card', 'AF ID'],
    ]
*/
export const prependDocLabelSelection = (prefix, doc, docTypeTaxonsToLabels) =>
    Object
        .entries(docTypeTaxonsToLabels)
        .filter(([docType]) => doc === null || docType === doc)
        .map(([docType, label]) => [docType, `${prefix}${label}`])
        .sort(([, a], [, b]) => a.localeCompare(b));


/*
Given country geonames mapped by 2-letter ISO code and customer-asserted ID prescriptions
return a prefix string for use in prependDocLabelSelection().

e.g.

computeGeoPrefix({
    countryNamesByAlpha2Code: {
        'us': 'United States',
        'ca': 'Canada',
        ...
    },
    prescriptions: {
        region: 'americas',
        country: 'ca',
    },
}) === 'Canada'
*/
export const computeGeoPrefix = ({
    countryNamesByAlpha2Code,
    prescriptions: {
        region,
        country,
    },
} = {}) => ((country)
    ? (countryNamesByAlpha2Code[country] || upper(country))
    : (region)
        ? naiveTitleCase(region)
        : '');

export const computeButtonLabels = ({
    prescriptions,
    countryNamesByAlpha2Code,
    docTypeTaxonsToLabels,
}) => {
    const prefixWithoutSpace = computeGeoPrefix({
        prescriptions,
        countryNamesByAlpha2Code,
    });

    const prefix = (prefixWithoutSpace) ? `${prefixWithoutSpace} ` : '';

    return prependDocLabelSelection(
        prefix,
        prescriptions.document,
        docTypeTaxonsToLabels);
};

export const getCountriesByAlpha2Code = () =>
    import('#/universal-framework/data/countries.json')
        .then(({ default: data }) =>
            Array
                .from(data)
                .reduce((p, { alpha2Code, label }) =>
                    Object.assign(p, { [alpha2Code.toLowerCase()]: label }), {}));

const validateExpirationDate = (expirationDate) => {
    return ((expirationDate.metadata.includesDate && expirationDate.maySubmit()) ||
    (!expirationDate.metadata.includesDate));
};

export const validationBase = (iface) => {
    return iface.fields.country.maySubmit() &&
        iface.fields.full_name.maySubmit() &&
        validateExpirationDate(iface.fields.expiration_date) &&
        iface.fields.issue_date.maySubmit() &&
        iface.fields.status.maySubmit() &&
        iface.fields.document_number.maySubmit() &&
        iface.fields.sex.maySubmit() &&
        iface.fields.date_of_birth.maySubmit() &&
        iface.externalApiAlertsResolved;
};

export const validatePassportSubmission = (iface) => {
    return validationBase(iface) &&
    (iface.fields.status.key === 'Invalid' || iface.getFaceImageRef().src) &&
    (iface.fields.status.key === 'Invalid' || iface.getFrontImageRef() || iface.getPassportImageRef());
};

export const validateIdCardSubmission = (iface) => {
    return validationBase(iface) &&
    iface.fields.province.maySubmit() &&
    (iface.fields.status.key === 'Invalid' || iface.getFaceImageRef().src) &&
    (iface.fields.status.key === 'Invalid' || iface.getFrontImageRef()) &&
    (iface.fields.status.key === 'Invalid' || iface.getBackImageRef()) &&
    (iface.prescriptions.country !== 'us' || iface.fields.address.maySubmit());
};

export const validateDriversLicenseSubmission = (iface) => {
    return (validateIdCardSubmission(iface) &&
    iface.fields.licenseclass.maySubmit());
};

// Returns interface that manages interactivity between user
// and the JurisdictionMappingInterface component.
const makePluginInstance = (verification, recordSpec, recordData, rest) =>
    factory((iface) => ({
        _loadingCropper: Promise.all([
            import('cropperjs/dist/cropper.min'),
            import('cropperjs/dist/cropper.min.css'),
        ]).then(([{ default: Cropper }]) => {
            iface.Cropper = Cropper;
            iface._loadingCropper = null;
        }),
        _asyncInit:
            Promise
                .all([
                    getCountriesByAlpha2Code(),
                    getManualVerificationDetails(recordSpec.authorizationId),
                ])
                .then(([countryNamesByAlpha2Code, { reportedAttributeType }]) => {
                    iface.prescriptions = extrapolateCustomerIdScanPrescriptions(reportedAttributeType);

                    iface.customerDocTypeLabels =
                        computeButtonLabels({
                            prescriptions: iface.prescriptions,
                            countryNamesByAlpha2Code,
                            docTypeTaxonsToLabels: {
                                id_card: 'ID Card',
                                drivers_license: 'Driver\'s License',
                                passport: 'Passport',
                            },
                        });

                    spa.redraw();
                })
                .then(() => {
                    iface.externalApiAlerts = prepareListOfAlerts(recordData);
                    // if amount of errors is higher than 0, it defaults to non-resolved
                    iface.externalApiAlertsResolved = !iface.externalApiAlerts.length;
                    iface.fields = {
                        address: attributeFieldFactory({
                            memberName: 'Address',
                            dataType: 'ExplodedAddress',
                            optional: (
                                rest[0] === 'passport' ||
                                iface.prescriptions.country !== 'us'
                            ),
                        }, recordData.registry),
                        full_name: attributeFieldFactory({
                            memberName: 'Name',
                            dataType: 'Name',
                            optional: false,
                        }, recordData.registry),
                        expiration_date: attributeFieldFactory({
                            memberName: 'Expiration Date',
                            dataType: 'date',
                            includesDate: true,
                            optional: false,
                            nonExpiring: false,
                        }, recordData.registry),
                        date_of_birth: attributeFieldFactory({
                            memberName: 'Date of Birth',
                            dataType: 'date',
                            optional: false,
                            preventFutureDates: true,
                        }, recordData.registry),
                        sex: attributeFieldFactory({
                            memberName: 'Sex',
                            dataType: 'EnumValue',
                            optional: false,
                            options: recordData.registry.get('Sex').options,
                        }, recordData.registry),
                        country: attributeFieldFactory({
                            memberName: 'Issuing Country',
                            dataType: 'StringValue',
                            optional: false,
                        }, recordData.registry),
                        province: attributeFieldFactory({
                            memberName: 'Issuing State/Province',
                            dataType: 'StringValue',
                            optional: rest[0] === 'passport',
                        }, recordData.registry),
                        document_number: attributeFieldFactory({
                            memberName: 'Document Number',
                            dataType: 'StringValue',
                            optional: false,
                        }, recordData.registry),
                        issue_date: attributeFieldFactory({
                            memberName: 'Issue Date',
                            dataType: 'date',
                            optional: false,
                            preventFutureDates: true,
                        }, recordData.registry),
                        licenseclass: attributeFieldFactory({
                            memberName: 'Class',
                            dataType: 'StringValue',
                            optional: rest[0] !== 'drivers_license',
                        }, recordData.registry),
                        status: attributeFieldFactory({
                            memberName: 'Status',
                            dataType: 'EnumValue',
                            optional: false,
                            options: recordData.registry.get('ValidInvalid').options,
                        }, recordData.registry),
                    };

                    iface._poll = startListening((data) => {
                        for (const [name, val] of Object.entries(data)) {
                            const f = iface.fields[name];

                            if (f && val) {
                                if (!['date', 'Name', 'Sex'].includes(f.metadata.dataType)) {
                                    f.value = val;
                                }

                                if (f.metadata.dataType === 'date') {
                                    f.value = `${val.month < 10 ? '0' + val.month : val.month}/${val.day < 10 ? '0' + val.day : val.day}/${val.year}`;
                                }

                                if (f.metadata.dataType === 'Name') {
                                    f.first = val.first;
                                    f.last = val.last;
                                    f.middle = val.middle;
                                }

                                if (f.metadata.memberName === 'Sex') {
                                    switch (val) {
                                    case 'M':
                                        f.key = f.value = 'Male';
                                        break;
                                    case 'F':
                                        // pay respects
                                        f.key = f.value = 'Female';
                                        break;
                                    default:
                                        f.key = f.value = 'Other';
                                    }
                                }
                            }
                            if (val) {
                                if (name === 'address') {
                                    iface.fields.province.value = val.state;
                                    iface.fields.country.value = val.country;
                                }

                                if (name === 'pdf417_data') {
                                    iface.fields.licenseclass.value = val.JurisdictionVehicleClass;
                                }
                            }
                        }
                        spa.redraw();
                    });
                })
                .then(() => {
                    iface._asyncInit = null;
                    spa.redraw();
                }),
        openFilterMenu: false,
        openTransformMenu: true,
        openCropMenu: false,
        showIdoDetailsForm: true,
        showVerificationFailures: true,
        showAddressForm: true,
        showDocDetailsForm: true,
        showFaceCroppingDialog: false,
        showRequestInfo: false,
        submitted: false,
        view: CheckIdInterface,
        recordSpec,
        selectedDocumentType: (ID_DOCUMENT_TYPE_TAXONS.includes(rest[0]))
            ? rest[0]
            : undefined,
        selectedImageOrdinal: 0,
        selectableImages: modelsFromRecordData(recordData),
        getSelectedImageRef: () =>
            iface.selectableImages[iface.selectedImageOrdinal],
        getFaceImageRef: () =>
            iface.selectableImages.find(({ forFace }) => forFace),
        getFrontImageRef: () =>
            iface.selectableImages.find(({ attributeType }) => /front/i.test(attributeType)),
        getBackImageRef: () =>
            iface.selectableImages.find(({ attributeType }) => /back/i.test(attributeType)),
        getPassportImageRef: () =>
            iface.selectableImages.find(({ attributeType }) => /\.image$/.test(attributeType)),
        setErrorMsgOnChild: (childField, errorMsg, useMsg) => {
            if (useMsg) {
                childField.external_errorText = errorMsg;
                childField.showError = true;
            } else {
                childField.external_errorText = null;
            }
            return useMsg;
        },
        /**
         * Validates the date fields in this form are interrelated.
         * @returns {boolean} Whether or not the date relationships are valid.
         */
        validateDateFieldRelationships: () => {
            if (!iface.fields) {
                return false;
            }

            // Convert dates to moment instances.
            const [mBirthDate, mIssueDate, mExpDate] =
                [iface.fields.date_of_birth, iface.fields.issue_date,
                    iface.fields.expiration_date].map(_dateFieldToMoment);

            // Issuance dates should not be before the birthdate.
            const isImpossibleIssueDate = mIssueDate.isValid() && mBirthDate.isValid() &&
                mIssueDate.isBefore(mBirthDate, 'day');
            // Expiration dates should not be before the Issuance Date.
            const isImpossibleExpDate = mIssueDate.isValid() && mExpDate.isValid() &&
                mExpDate.isBefore(mIssueDate, 'day');

            const relationshipsOK = [
                [iface.fields.date_of_birth, 'Birthday must be after 01/01/1902', mBirthDate.isBefore('1902-01-01')],
                [iface.fields.issue_date, 'The Issue Date must be after the Birth Date', isImpossibleIssueDate],
                [iface.fields.expiration_date, 'The Expiration Date must be after the Issue Date', isImpossibleExpDate],
            ].reduce(
                (prevIsOK, setErrorMsgArgs) => prevIsOK && !iface.setErrorMsgOnChild(...setErrorMsgArgs),
                true);

            return relationshipsOK;
        },
        maySubmit: () => {
            if (!iface.fields || !iface.validateDateFieldRelationships()) {
                return false;
            }

            return ((iface.fields)
                ? ({
                    'passport': validatePassportSubmission,
                    'id_card': validateIdCardSubmission,
                    'drivers_license': validateDriversLicenseSubmission,
                }[iface.selectedDocumentType] || (() => false))(iface)
                : false);
        },
        encode: () =>
            ({
                $objectType: 'ManualVerificationRecord',
                verification_record: {
                    $objectType: 'DocumentVerificationRecord',
                    country: iface.fields.country.encode(),
                    province: iface.fields.province.encode(),
                    status: iface.fields.status.key,
                },
                face_image: iface.encodeImage(iface.getFaceImageRef()),
                front_image: iface.encodeImage(iface.getFrontImageRef()),
                back_image: iface.encodeImage(iface.getBackImageRef()),
                image: iface.encodeImage(iface.getPassportImageRef() || iface.getFrontImageRef()),
                address: iface.fields.address.encode(),
                full_name: iface.fields.full_name.encode(),
                country: iface.fields.country.encode(),
                expiration_date: iface.fields.expiration_date.encode(),
                date_of_birth: iface.fields.date_of_birth.encode(),
                sex: iface.fields.sex.encode(),
                document_number: iface.fields.document_number.encode(),
                issue_date: iface.fields.issue_date.encode(),
                class: iface.fields.licenseclass.encode(),
                licenseclass: iface.fields.licenseclass.encode(),
            }),
        encodeImage: (ref) =>
            ((ref && ref.src)
                ? createBinaryDataFromDataUrl(ref.src, { cropped: ref.cropped })
                : null),
        applyFaceCrop: () => {
            const fr = iface.getFaceImageRef();
            fr.updateSrc(iface.cropper.getCroppedCanvas().toDataURL());
            fr.labelText = 'Face image from document';
            iface.closeFaceCaptureDialog();
        },
        onAssertIdDocumentType: (type) => {
            if (type) {
                const segments = [
                    'verifications',
                    recordSpec.ido.split('@')[0],
                    recordSpec.recordType,
                    type,
                ];

                const route = segments
                    .filter((v) => v)
                    .map(spa.$window.encodeURIComponent)
                    .map((r) => `/${r}`)
                    .join('');

                router.go(route);
            } else {
                // User asserted document does not appear to be
                // one of the expected types. Treat as Cancel
                // button for now.

                router.go('/verifications');
            }
        },
        onNewBrightness: (val) =>
            Object.assign(iface.getSelectedImageRef(), {
                brightness: Number(val),
                dirty: true,
            }),
        onNewContrast: (val) =>
            Object.assign(iface.getSelectedImageRef(), {
                contrast: Number(val),
                dirty: true,
            }),
        onNewImageAngle: (valDegrees) =>
            Object.assign(iface.getSelectedImageRef(), {
                radians: toRadians(Number(valDegrees)),
                dirty: true,
            }),
        onNewImageScale: (val) =>
            Object.assign(iface.getSelectedImageRef(), {
                scalar: Number(val) || 1,
                dirty: true,
            }),
        onNewImageXOff: (val) =>
            Object.assign(iface.getSelectedImageRef(), {
                xTranslate: Number(val),
                dirty: true,
            }),
        onNewImageYOff: (val) =>
            Object.assign(iface.getSelectedImageRef(), {
                yTranslate: Number(val),
                dirty: true,
            }),
        onReset: () => {
            const ref = iface.getSelectedImageRef();

            Object.assign(ref, {
                brightness: 100,
                contrast: 100,
                radians: 0,
                yScale: 1,
                xScale: 1,
                scalar: 1,
                xTranslate: 0,
                yTranslate: 0,
                dirty: false,
            });

            iface.dirtyCropper();
            ref.updateSrc(ref.originalSrc);

            // Man, this takes me back. Calling spa.redraw directly
            // or omitting this line will cause cropperjs to display
            // a gray image and end up out of sync. This, however,
            // schedules the redraw to start once Mithril and CropperJS
            // had their say.
            setTimeout(spa.redraw);
        },
        selectFilterMenu: () =>
            Object.assign(iface, { openFilterMenu: true, openTransformMenu: false, openCropMenu: false }),
        selectTransformationMenu: () =>
            Object.assign(iface, { openFilterMenu: false, openTransformMenu: true, openCropMenu: false }),
        selectCropMenu: () =>
            Object.assign(iface, { openFilterMenu: false, openTransformMenu: false, openCropMenu: true }),
        dirtyCropper: () => {
            if (iface.cropper) {
                iface.cropper.destroy();

                const node = spa.$window.document.querySelector('.cropper-container');

                if (node) {
                    node.remove();
                }
            }
        },
        onCroppableImageMounted: (id) => {
            const elem = spa.$window.document.getElementById(id);

            if (elem) {
                iface.dirtyCropper();
                iface.cropper = new iface.Cropper(elem);
            } else {
                logger.warn(`#${elem} not present for Cropper.`);
            }
        },
        shiftOrdinal: (shift) =>
            (iface.selectedImageOrdinal =
                modulo(
                    Number(iface.selectedImageOrdinal) + shift,
                    iface.selectableImages.length)),
        selectPreviousImage: () => {
            iface.shiftOrdinal(-1);
            if (!iface.getSelectedImageRef().isLocal && iface.showImageAdjustmentDialog) {
                iface.getSelectedImageRef().isLocal = !iface.getSelectedImageRef().isLocal;
            }
        },
        selectNextImage: () => {
            iface.shiftOrdinal(1);
            if (!iface.getSelectedImageRef().isLocal && iface.showImageAdjustmentDialog) {
                iface.getSelectedImageRef().isLocal = !iface.getSelectedImageRef().isLocal;
            }
        },
        selectImageByOrdinal: (ordinal) =>
            (iface.selectedImageOrdinal = Number(ordinal)),
        toggleIdoDetailsForm: (open) =>
            (iface.showIdoDetailsForm = !open),
        toggleAddressForm: (open) =>
            (iface.showAddressForm = !open),
        toggleDocDetailsForm: (open) =>
            (iface.showDocDetailsForm = !open),
        toggleVerificationFailures: (open) =>
            (iface.showVerificationFailures = !open),
        editSelectedImage: () => {
            iface.showImageAdjustmentDialog = true;
            if (!iface.getSelectedImageRef().isLocal) {
                iface.getSelectedImageRef().isLocal = !iface.getSelectedImageRef().isLocal;
            }
        },
        commitImageCrop: () => {
            const ref = iface.getSelectedImageRef();

            Object.assign(ref, {
                brightness: 100,
                contrast: 100,
                radians: 0,
                scalar: 1,
                xTranslate: 0,
                yTranslate: 0,
                yScale: 1,
                xScale: 1,
            });

            ref.updateSrc(iface.cropper.getCroppedCanvas().toDataURL());

            iface.closeEditDialog();
        },
        applyAdjustments: () => {
            const ref = iface.getSelectedImageRef();

            Object.assign(ref, {
                brightness: 100,
                contrast: 100,
                radians: 0,
                scalar: 1,
                xTranslate: 0,
                yTranslate: 0,
                yScale: 1,
                xScale: 1,
                dirty: false,
            });

            ref.updateSrc(spa.$window.document.getElementById('adj-preview').toDataURL());
        },
        closeEditDialog: () => {
            // Use to sidestep issues with opening dialog when crop menu is selected.
            // TODO: This is a jQuery-era patch job.
            iface.selectFilterMenu();
            iface.showImageAdjustmentDialog = false;
        },
        captureFace: () => {
            iface.showFaceCroppingDialog = true;

            const firstWithSrc = iface.selectableImages.findIndex(({ originalSrc }) => originalSrc);
            const frontOrdinal = iface.selectableImages.findIndex(({ attributeType }) => attributeType.indexOf('front') > -1);
            const passportOrdinal = iface.selectableImages.findIndex(({ attributeType }) => attributeType.indexOf('passport') > -1);

            iface.selectedImageOrdinal = (frontOrdinal > -1)
                ? frontOrdinal
                : (passportOrdinal > -1)
                    ? passportOrdinal
                    : firstWithSrc;
        },
        closeFaceCaptureDialog: () =>
            (iface.showFaceCroppingDialog = false),
        toggleRequestInfo: () => {
            iface.showRequestInfo = !iface.showRequestInfo;
        },
        flipX: () => {
            iface.getSelectedImageRef().xScale = -1 * iface.getSelectedImageRef().xScale;
        },
        flipY: () => {
            iface.getSelectedImageRef().yScale = -1 * iface.getSelectedImageRef().yScale;
        },
    }));

// Returns interface that manages interactivity between user
// and the JurisdictionMappingInterface component.

export default (verification, recordSpec, recordData, rest) =>
    events.prescribe((iface) => [
        ['assert-id-document-type', iface.onAssertIdDocumentType],
        ['flip-horizontally', iface.flipX],
        ['commit-crop', iface.commitImageCrop],
        ['flip-vertically', iface.flipY],
        ['select-image', iface.selectImageByOrdinal],
        ['new-image-angle', iface.onNewImageAngle],
        ['new-image-scale', iface.onNewImageScale],
        ['new-image-x-off', iface.onNewImageXOff],
        ['new-image-y-off', iface.onNewImageYOff],
        ['new-brightness-value', iface.onNewBrightness],
        ['new-contrast-value', iface.onNewContrast],
        ['select-prev-image', iface.selectPreviousImage],
        ['select-next-image', iface.selectNextImage],
        ['reset-selected-adjustments', iface.onReset],
        ['save-selected-adjustments', iface.applyAdjustments],
        ['edit-selected-image', iface.editSelectedImage],
        ['close-edit-dialog', iface.closeEditDialog],
        ['toggle-request-info', iface.toggleRequestInfo],
        ['toggle-ido-details-form', iface.toggleIdoDetailsForm],
        ['toggle-address-form', iface.toggleAddressForm],
        ['toggle-doc-details-form', iface.toggleDocDetailsForm],
        ['toggle-verification-failures', iface.toggleVerificationFailures],
        ['select-image-adjustment-dialog-filters-menu', iface.selectFilterMenu],
        ['select-image-adjustment-dialog-transform-menu', iface.selectTransformationMenu],
        ['select-image-adjustment-dialog-crop-menu', iface.selectCropMenu],
        ['capture-missing-face', iface.captureFace],
        ['apply-face-crop', iface.applyFaceCrop],
        ['croppable-image-mounted', iface.onCroppableImageMounted],
        ['cancel-face-crop', iface.closeFaceCaptureDialog],
    ])(makePluginInstance(verification, recordSpec, recordData, rest));
