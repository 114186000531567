import { m } from '#/browser-framework';
import { Button, Overlay, Spinner } from '#/browser-framework/comps';


export const ConfirmDialog = {
    textEntry: '',
    waiting: false,
    view({
        state,
        children,
        attrs: {
            oncancel,
            onconfirm,
            confirmText,
            cancelButtonText = 'Cancel',
            continueButtonText,
        },
    }) {
        return m('aside.ConfirmDialog',
            m('header.ConfirmDialog__body',
                children),
            m('form.ConfirmDialog__form',
                m('p.ConfirmDialog__instructions',
                    m.trust('To confirm this operation, type &ldquo;'),
                    confirmText,
                    m.trust('&rdquo; here')),
                m('input.ConfirmDialog__textField', {
                    value: state.textEntry,
                    oninput(e) {
                        state.textEntry = e.target.value;
                    },
                })),
            m('footer.ConfirmDialog__controls',
                m(Button, {
                    onpress: oncancel,
                    flags: {
                        secondary: true,
                    },
                }, cancelButtonText),
                m(Button, {
                    disabled: state.waiting || state.textEntry !== confirmText,
                    onpress() {
                        state.waiting = true;

                        Promise
                            .resolve(onconfirm())
                            .catch(() => {
                            })
                            .then(() => {
                                state.waiting = false;
                            });
                    },
                    flags: {
                        danger: true,
                    },
                }, continueButtonText)),
            (state.waiting) && m(Overlay, m(Spinner)));
    },
};
