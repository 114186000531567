import { async, b64, logger, m, strings, xhr } from '#/browser-framework';
import { getUserIdToken } from '#/browser-framework/firebase';

export function subjectifyRelyingPartyModel(rp) {
    return {
        _objective: rp,
        status: strings.capitalizeWord(rp.status || 'pending'),
        notificationConfig: Object.assign({
            recipients: [],
            sendNotify: {
                idOwner: {},
                relyingParty: {},
            },
            sendReminder: {
                idOwner: {},
                relyingParty: {},
            },
        }, rp.notificationConfig || {}),
        admins: rp.admins || [],
        displayName: rp.displayName,
        entityName: rp.name,
        supportEmail: rp.supportEmail,
    };
}

// REST-AJAX binding.
export function junctionClient(o) {
    return getUserIdToken()
        .then((jwt) => {
            o.url = deploy.WEB_PUBLIC_JUNCTION_SERVICE_URL_PREFIX + o.url;
            o.config = (xo) => {
                xo.setRequestHeader('Authorization', `Bearer ${jwt}`);
            };
            return xhr.json(o);
        });
}

// Return an attribute value associated with an IDO sharing agreement.
// This data is used for reviews during manual verification.
function fetchIdoAttributeValue(authorizationId, attrId) {
    return junctionClient({
        url: `/manualVerifications/${authorizationId}/attributeData/${attrId}`,
    });
}

/*
The service has its own type system encoded as data that calls for
single inheritance anemic struct heirarchies built over network I/O.

The asprin is by the printer.
*/
function resolveMembers(spec, registry) {
    return spec.members.map((s) => {
        const resolved = Object.assign({}, s, registry.get(s.dataType));

        if (resolved.members) {
            resolved.members = resolveMembers(resolved, registry);
        }

        // Children inherit optionality
        if (resolved.optional && Array.isArray(resolved.members)) {
            resolved.members = resolved.members.map((k) => Object.assign(k, { optional: true }));
        }

        return resolved;
    });
}


function aggregateDataTypeDefinitions(registry) {
    for (const [, extended] of registry) {
        const base = registry.get(extended.baseType);

        if (base && base.members) {
            extended.members = base.members.concat(extended.members || []);
        }

        if (extended.members) {
            extended.members = resolveMembers(extended, registry);
        }
    }

    return registry;
}


const getAllDataTypes = async.once(() => {
    return junctionClient({ url: '/metadata/attrDataTypes' }).then(({dataTypes}) => {
        const registry = dataTypes.concat([
            { dataType: 'StringValue' },
            { dataType: 'DateValue' },
            { dataType: 'MultilineString' },
        ]).reduce((p, c) => {
            return p.set(c.dataType, c);
        }, new Map());

        return aggregateDataTypeDefinitions(registry);
    });
});


// Take a single IDO manual verification record and flesh out all of the data
// the ops team needs to resolve it for the RP.
export function fetchRecordData(r) {
    let recordSpec;

    return junctionClient({ url: `/metadata/attrTypes/${r.recordType}` })
        .then((record) => {
            recordSpec = record;

            return Promise.all([
                getAllDataTypes(),
                Promise.all(r.attrIds.map((attrId) => fetchIdoAttributeValue(r.authorizationId, attrId))),
            ]);
        })
        .then(([registry, values]) => {
            return {
                registry,
                dataType: recordSpec.dataType,
                values,
                resolvedMembers: registry.get(recordSpec.dataType).members,
            };
        });
}


// Get the SUMMARIZED list of pending verifications that the ops team needs to process.
// The verifications themselves are lazily loaded using fetchVerificationData()
// given the time and space complexity involved in resolving their records.
export function getVerifications(searchText) {
    return junctionClient({
        url: (searchText)
            ? `/manualVerifications?searchText=${encodeURIComponent(searchText)}`
            : '/manualVerifications',
    }).then(({ verifications }) => verifications);
}

// Send a record up to the verification for processing.
export function submitRecord(authorizationId, data) {
    return junctionClient({
        url: `/manualVerifications/${authorizationId}`,
        method: 'POST',
        data,
    });
}

export function getManualVerificationDetails(authorizationId) {
    return junctionClient({
        url: `/manualVerifications/${authorizationId}/customer`,
        method: 'GET',
    });
}


// Send an error in lieu of a record as a terminal state for a manual verification.
export function reportError(authorizationId, errorString) {
    return junctionClient({
        url: `/manualVerifications/${authorizationId}/reportError`,
        method: 'POST',
        data: {
            error: errorString,
        },
    });
}

export function getRpInfo(name) {
    return junctionClient({url: `/relyingparties/${name}`});
}

// Get a list of RPs with their onboarding status for review.
export async function getAllRps({
    searchText,
    ascending,
}) {
    const queryStr = JSON.stringify({searchText, ascending});

    logger.debug('RP query', queryStr);

    const query = m.buildQueryString({
        query: b64.encodeString(queryStr),
        bust: (new Date()).getTime(),
    });

    return (await junctionClient({url: `/relyingparties?${query}`})).relyingParties.map(subjectifyRelyingPartyModel);
}

// For C, and U out of CRUD.
export function saveNewRp({
    admins,
    displayName,
    name,
    notificationConfig,
    supportEmail,
}) {
    return junctionClient({
        method: 'POST',
        url: '/relyingparties',
        data: {
            admins,
            displayName,
            name,
            notificationConfig,
            supportEmail,
        },
    });
}


export function saveExistingRp({
    admins,
    name,
    supportEmail,
    displayName,
    notificationConfig,
    userAuthAudience,
    userAuthMode,
    verifyApiEnabled,
}) {
    return junctionClient({
        method: 'PUT',
        url: `/relyingparties/${name}`,
        data: {
            profile: {
                admins,
                supportEmail,
                displayName,
            },
            notificationConfig,
            userAuthAudience,
            userAuthMode,
            verifyApiEnabled,
        },
    });
}

export function searchIdos(searchText) {
    return (searchText)
        ? junctionClient({
            method: 'GET',
            url: `/idos?searchString=${encodeURIComponent(searchText.trim())}`,
        }).then(({ idos }) => idos)
        : Promise.resolve([]);
}

export function getIdoDetails(idOwnerId) {
    return junctionClient({
        method: 'GET',
        url: `/idos/${encodeURIComponent(idOwnerId)}/details`,
    });
}

export function deleteAttributeDatum(idOwnerId, attributeDataId) {
    return junctionClient({
        method: 'DELETE',
        url: `/idos/${encodeURIComponent(idOwnerId)}/${encodeURIComponent(attributeDataId)}`,
    });
}

export function deleteRequest(requestId) {
    return junctionClient({
        method: 'DELETE',
        url: `/requests/${encodeURIComponent(requestId)}`,
    });
}

export function timeoutRequest(requestId) {
    return junctionClient({
        method: 'PUT',
        url: `/requests/${encodeURIComponent(requestId)}/timeout`,
    });
}

export function runMacro(idOwnerId, macroName, args = {}) {
    return junctionClient({
        method: 'POST',
        url: '/idos/idoModificationRequest',
        data: {
            idOwnerId,
            macroName,
            args,
        },
    });
}

export function getMacros() {
    return junctionClient({
        method: 'GET',
        url: '/idos/macros',
    }).then(({ macros }) => macros);
}


export const junctionServiceClient = { getAllRps, getRpInfo };
