import debounce from 'debounce';

import { lets } from '#/universal-framework/functions';
import { factory, permuteEntries } from '#/universal-framework/objects';
import { alphabetize, naiveTitleCase } from '#/universal-framework/strings';
import { modulo } from '#/universal-framework/numbers';
import { utcTimestampToLocaleString, CLEAR_DATE } from '#/universal-framework/dates';


import { EID_MANUAL_JURISDICTION_RESULT_TYPE } from '#/universal-framework/typeSystem';
import { bem, m, spa, tx } from '#/browser-framework';
import { Dropdown, KeyValueSet, Named, Spinner } from '#/browser-framework/comps';
import { purecomp } from '#/browser-framework/vcomps';

import { Address, EvidentDate, Jurisdiction, Name } from '#/evident-attributes/views';

import { RequestInfo } from '#/ops-facing/views/RequestInfo';

const _classifyJurisdictionPresence = (block, needsReview, selected, userAsserted) =>
    tx({
        [block]: true,
        [`${block}--needsReview`]: needsReview,
        [`${block}--userAsserted`]: userAsserted,
        [`${block}--selected`]: selected,
    });

// This form model exposes an imperative interface to select
// United States states and counties. It may compute
// a Jurisdiction object reflecting user selections.
export const _makeJurisdictionMappingFormModel = (geoData) => {
    const iface = {
        stateIndex: 0,
        stateSelectionIndex: -1,
        countyIndex: 0,
        countySelectionIndex: -1,
        states: alphabetize(Object.keys(geoData)),
        geoData,
    };

    iface.getCountyNames = () =>
        geoData[iface.states[iface.stateSelectionIndex]]
            .map(([, countyName]) => countyName);

    iface.getJurisdiction = () =>
        lets([iface.states[iface.stateSelectionIndex], iface.countySelectionIndex],
            (st, ci) =>
                ((st && geoData[st][ci])
                    ? ({
                        $objectType: 'Jurisdiction',
                        state: st,
                        country: 'United States of America',
                        fips_number: geoData[st][ci][0],
                        county: geoData[st][ci][1],
                    })
                    : null));

    iface.selectState = (index, actual) =>
        Object.assign(iface, {
            stateIndex: index,
            stateSelectionIndex: actual,
            countyIndex: 0,
            countySelectionIndex: -1,
        });

    iface.selectCounty = (index, actual) =>
        Object.assign(iface, {
            countyIndex: index,
            countySelectionIndex: actual,
        });

    return iface;
};

// Returns an imperative interface that manages a list of
// history data and a jurisdiction form model. This may be
// encoded to a form that holds jurisdiction data. By precedence,
// original jurisdiction data comes before the form model's jursidiction.
export const _makeHistoryItemModel = (geoData, historyData) =>
    factory((iface) => ({
        historyData,
        formModel: _makeJurisdictionMappingFormModel(geoData),
        encode: () =>
            historyData.county_or_jurisdiction ||
            iface.formModel.getJurisdiction(),
    }));

// Manages selection of history records.
export const _makeHistoryNavModel = (geoData, originalOptions) =>
    factory((iface) => ({
        ordinal: 0,
        options: originalOptions.map((historyData) =>
            _makeHistoryItemModel(geoData, historyData)),
        select: ((o) =>
            (iface.ordinal = o)),
        shiftSelection: ((shift) =>
            (iface.ordinal = modulo(iface.ordinal + shift, iface.options.length))),
        getValue: () =>
            iface.options[iface.ordinal],
        maySubmit: () =>
            iface
                .options
                .every((item) => Boolean(item.encode())),
        encode: () =>
            iface
                .options
                .map((item) => item.encode()),
    }));

// Extracts criminal case history from Evident ID record data.
export const _extractCaseHistory = (geoData, recordData) =>
    _makeHistoryNavModel(
        geoData,
        recordData.values[0].content.criminal_records,
        'criminal_records');

// Extracts address history from Evident ID record data.
export const _extractAddressHistory = (geoData, recordData) =>
    _makeHistoryNavModel(
        geoData,
        recordData.values[0].content.address_records,
        'address_records');

export const _extractOffenderDetails = (recordData) =>
    ((recordData.values[0])
        ? ((recordData
            .values[0]
            .content
            .criminal_records
            .find(({ offender_details: od }) => od) || {})
            .offender_details)
        : null);

// Click to focus an item from the IDO's history.
const HistoryOption = lets([bem`HistoryOption iconContainer contentContainer upper lower`],
    ({ block, iconContainer, contentContainer, upper, lower }) =>
        purecomp(({
            userAsserted,
            selected,
            jurisdiction,
        }, children) =>
            m(_classifyJurisdictionPresence(block, !jurisdiction, selected, userAsserted),
                m(iconContainer,
                    m(Named.Icon, {
                        name: (userAsserted)
                            ? 'ellipsis'
                            : (jurisdiction)
                                ? 'checkmark'
                                : 'alert',
                    })),
                m(contentContainer,
                    m(upper, (jurisdiction)
                        ? `(${jurisdiction.county}) ${jurisdiction.state}`
                        : 'Jurisdiction not set'),
                    m(lower, children)))));

// A HistoryOption used for address history
const AddressOption = lets([bem`AddressOption`],
    ({ block }) =>
        purecomp(({
            historyData: {
                address_details: {
                    address,
                },
            },
            jurisdiction,
            selected,
            userAsserted,
        }) =>
            m(HistoryOption, { jurisdiction, selected, userAsserted },
                m(block, m(Address, address)))));

// A HistoryOption used for criminal case history
const CaseOption = lets([bem`CaseOption caseNumberContainer`],
    ({ block, caseNumberContainer }) =>
        purecomp(({
            historyData: {
                crime_details: cd,
            },
            jurisdiction,
            selected,
            userAsserted,
        }) =>
            m(HistoryOption, { jurisdiction, selected, userAsserted },
                m(block,
                    m(`span${caseNumberContainer}`,
                        `Case #${cd.case_number}`)))));

// This shows up when the interface is first rendered.
const JurisdictionMappingInstructions =
    purecomp(() =>
        m('article.JurisdictionMappingInstructions',
            m('p',
                'We often get incomplete or messy records that make it hard ',
                'to know what criminal cases or addresses belong to what jurisdiction. ',
                'Select one of the red cases or addresses on the right to bring up ',
                'related details, and supply the correct jurisdiction.')));

// This form appears when the user selects a history item with a missing
// jurisdiction. The user may select a state and county.
const JurisdictionMappingForm =
    lets([bem`JurisdictionMappingForm question stateField countyField notesField`],
        ({ block, stateField, countyField, question }) =>
            purecomp(({ formModel }) =>
                m(block,
                    m(`p${question}`,
                        'In what jurisdiction does this record belong?'),
                    m(stateField,
                        m(Dropdown, {
                            id: 'juris-state-dropdown',
                            placeholder: 'Select a state or territory',
                            options: formModel.states,
                            select: formModel.selectState,
                            value: formModel.stateIndex,
                        })),
                    m(countyField,
                        m(Dropdown, (formModel.stateSelectionIndex > -1)
                            ? {
                                id: 'juris-county-dropdown',
                                placeholder: 'Select a jurisdiction',
                                disabled: false,
                                options: formModel.getCountyNames(),
                                select: formModel.selectCounty,
                                value: formModel.countyIndex,
                            }
                            : {
                                id: 'juris-county-dropdown',
                                disabled: true,
                                placeholder: '← Select a state or territory first',
                                options: [],
                                select: () => {},
                                value: 0,
                            })))));

// Shows extra info about the IDO.
export const OffenderDetails = purecomp(({ offender }) =>
    m('.OffenderDetails',
        m('h3', 'Offender details'),
        ((offender)
            ? m(KeyValueSet, {
                pairs: lets([permuteEntries(offender, [
                    'name',
                    'dob',
                    'address',
                ])], ({ permutation }) =>
                    permutation
                        .map(([k, v]) => [
                            naiveTitleCase(k.replace(/_/g, ' ')),
                            ((v)
                                ? (({
                                    'name': () => m(Name, v),
                                    'dob': () => ((v.$objectType === 'date')
                                        ? m(EvidentDate, v)
                                        : utcTimestampToLocaleString(v.timestamp, 'en-US', CLEAR_DATE)),
                                    'address': () => m(Address, v),
                                })[k] || (() => v))()
                                : 'Unavailable'),
                        ])),
            })
            : m('p', 'No data available'))));

// Renders navigation for selecting history records.
const IndividualRecordHistoryNavigation =
    lets([bem`IndividualRecordHistoryNavigation item`],
        ({ block, item }) =>
            purecomp(
                ({ historyKind }, children) =>
                    m(block,
                        children.map((child, key) =>
                            m(item, {
                                key,
                                'data-history-kind': historyKind,
                                'data-ordinal': key,
                            }, child)))));


const CaseDetails = purecomp(({ historyData }) =>
    m(KeyValueSet, {
        pairs: lets([permuteEntries(historyData.crime_details, [
            'case_number',
            'non_normalized_jurisdiction',
            'arresting_agency',
            'court_name',
        ])], ({ permutation }) =>
            permutation
                .map(([k, v]) => [
                    ({
                        'non_normalized_jurisdiction': 'Original (non-normalized) jurisdiction data',
                    })[k] || naiveTitleCase(k.replace(/_/g, ' ')),
                    (v)
                        ? ({
                            'non_normalized_jurisdiction': m(Jurisdiction, v),
                        })[k] || v
                        : 'Unavailable',
                ])),
    }));


const AddressDetails = purecomp(({
    historyData: {
        address_details: {
            address,
        },
    },
}) =>
    m('.AddressDetails',
        m(Address, address)));

const HistoryItemDetails =
    purecomp(({ historyKind, historyData }) =>
        m((historyKind === 'caseHistory')
            ? CaseDetails
            : AddressDetails, { historyData }));


// Shows a combination of a jurisdiction mapping form and related history
// details. The form will only appear if a jurisdiction is missing in the
// original data. That said, be sure you pass only the original history
// data to this component unless you want to risk the form disappearing
// the instant you select a state and county.
const JurisdictionMapper =
    lets([bem`JurisdictionMapper formContainer historyItemContainer`],
        ({ block, formContainer, historyItemContainer }) => ({
            // We do not use position: sticky because it causes an unwanted scroll in Chrome.
            // https://stackoverflow.com/q/50804028/394397
            sticky: debounce(spa.redraw, 100),
            oncreate: (vnode) => spa.$window.addEventListener('scroll', vnode.state.sticky),
            onremove: (vnode) => spa.$window.removeEventListener('scroll', vnode.state.sticky),
            view: ({ attrs: { formModel, historyKind, historyData } }) =>
                m(block, { style: `top:${spa.$window.scrollY}px` },
                    (!historyData.county_or_jurisdiction) &&
                        m(formContainer,
                            (formModel)
                                ? m(JurisdictionMappingForm, {
                                    formModel,
                                })
                                : m(Spinner)),
                    m(historyItemContainer,
                        m(HistoryItemDetails, {
                            historyKind,
                            historyData,
                        }))),
        }));

// Captures a usage pattern for IndividualRecordHistoryNavigation
// to abstract over similarities in navigating address and case records.
const ScopedHistoryNavigation =
    purecomp(({ historyKind, record, comp }) =>
        ((record[historyKind].options.length > 0)
            ? m(IndividualRecordHistoryNavigation, { historyKind },
                record[historyKind].options.map(({ historyData, formModel }, key) =>
                    m(comp, {
                        key,
                        historyData,
                        jurisdiction: (
                            historyData.county_or_jurisdiction ||
                            formModel.getJurisdiction()
                        ),
                        userAsserted: (
                            !historyData.county_or_jurisdiction &&
                            formModel.stateIndex > 0 &&
                            formModel.countyIndex > 0
                        ),
                        selected: (
                            record.historyKind === historyKind &&
                            record[historyKind].ordinal === key
                        ),
                    })))
            : m('p', 'No records')));


// Aggregate view that brings everything together. A user interacts with this component
// to map jurisdictions across an individual's criminal history and known addresses.
const JurisdictionMappingInterface =
    lets([bem`JurisdictionMappingInterface historySelections recordForm requestDetails offenderDetails`],
        ({ block, historySelections, recordForm, requestDetails, offenderDetails }) => ({
            oncreate(vnode) {
                vnode.state.listener = (e) => {
                    const ordinalOffset = ({
                        '38': -1, // Up arrow key
                        '40': 1, // Down arrow key
                    })[e.keyCode] || 0;

                    if (e.shiftKey && ordinalOffset) {
                        vnode.attrs.record.selectNextShortcut(ordinalOffset);
                    }
                };

                spa.$window.document.addEventListener('keydown', vnode.state.listener);
            },
            onremove: (vnode) =>
                spa.$window.document.removeEventListener('keypress', vnode.state.listener),
            view: ({ attrs: { record } }) =>
                ((record.vowGeoData)
                    ? m(Spinner)
                    : m(`section${block}`, { onclick: record.historyItemSelected },
                        m(`section${recordForm}`,
                            (record.historyKind)
                                ? lets([record[record.historyKind].getValue()], ({ formModel, historyData }) =>
                                    m(JurisdictionMapper, {
                                        formModel,
                                        historyKind: record.historyKind,
                                        historyData,
                                    }))
                                : m(JurisdictionMappingInstructions)),
                        m(`section${requestDetails}`,
                            m(RequestInfo, record.recordSpec)),
                        m(`section${offenderDetails}`,
                            m(OffenderDetails, { offender: record.offender })),
                        m(`section${historySelections}`,
                            m('h3', 'Case history'),
                            m(ScopedHistoryNavigation, {
                                historyKind: 'caseHistory',
                                record,
                                comp: CaseOption,
                            }),
                            m('h3', 'Address history'),
                            m(ScopedHistoryNavigation, {
                                historyKind: 'addressHistory',
                                record,
                                comp: AddressOption,
                            })))),
        }));

// Returns interface that manages interactivity between user
// and the JurisdictionMappingInterface component.
export default (verification, recordSpec, recordData) =>
    factory((iface) => ({
        submitted: false,
        view: JurisdictionMappingInterface,
        recordSpec,
        maySubmit: () =>
            EID_MANUAL_JURISDICTION_RESULT_TYPE(iface.encode()),

        // Loads form model using hefty county data
        // Source: https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/home/?cid=nrcs143_013697
        vowGeoData: spa.redrawAfter(() =>
            import('#/universal-framework/data/usaCounties.json')
                .then(({default: geoData}) => Object.assign(iface, {
                    vowGeoData: null,
                    geoData,
                    // Holds cases and known addresses that user can pull up to review.
                    caseHistory: _extractCaseHistory(geoData, recordData),
                    addressHistory: _extractAddressHistory(geoData, recordData),
                })))(),

        historyItemSelected: (event) =>
            lets([event.target.closest('[data-ordinal][data-history-kind]')], (elem) => {
                if (elem) {
                    iface.historyKind = elem.getAttribute('data-history-kind');
                    iface[iface.historyKind].select(parseInt(
                        elem.getAttribute('data-ordinal'), 10));
                }
            }),

        selectNextShortcut: (ordinalOffset) => {
            if (iface.historyKind) {
                iface[iface.historyKind].shiftSelection(ordinalOffset);
                spa.redraw();
                setTimeout(() => {
                    const node = spa.$window.document.querySelector('select');

                    if (node) {
                        node.focus();
                    }
                }, 0);
            }
        },

        offender: _extractOffenderDetails(recordData),

        encode: () =>
            ((iface.caseHistory && iface.addressHistory)
                ? ({
                    $objectType: 'ManualJurisdictionResult',
                    criminal_records_jurisdictions: iface
                        .caseHistory
                        .encode(),
                    address_records_jurisdictions: iface
                        .addressHistory
                        .encode(),
                })
                : null),
    }));
