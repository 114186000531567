import { m, strings } from '#/browser-framework';

import DateInput from './DateInput';
import EnumSelection from './EnumSelection';
import IntegerInput from './IntegerInput';
import MultiLineTextInput from './MultiLineTextInput';
import PostalCode from './PostalCode';
import ExplodedAddress from './ExplodedAddress';
import FieldList from './FieldList';
import FullNameField from './FullNameField';
import PrimitiveDump from './PrimitiveDump';
import SingleLineTextInput from './SingleLineTextInput';
import SingleFileUpload from './SingleFileUpload';


export function constructorFromType(dataType) {
    /* eslint-disable object-shorthand */
    return ({
        'ExplodedAddress': ExplodedAddress,
        'BinaryData': SingleFileUpload,
        'date': DateInput,
        'DateValue': DateInput,
        'EnumValue': EnumSelection,
        'Name': FullNameField,
        'IntValue': IntegerInput,
        'MultilineString': MultiLineTextInput,
        'PostalCode': PostalCode,
        'PostalCodeSuffix': SingleLineTextInput,
        'StringValue': SingleLineTextInput,
    })[dataType];
    /* eslint-enable object-shorthand */
}

export function constructorFromGenericType(dataType) {
    const tokens = dataType.split(/[\[\]]/g).filter((v) => v);

    return ({
        'List': FieldList,
    })[tokens[0]];
}

export function constructorFromDataShape(metadata) {
    return (metadata.members)
        ? PrimitiveDump
        : null;
}


export function selectConstructor(metadata) {
    return (
        constructorFromGenericType(metadata.dataType) ||
        constructorFromType(metadata.dataType) ||
        constructorFromType(metadata.baseType) ||
        constructorFromDataShape(metadata)
    );
}

function validatePlugin(plugin) {
    if (
        typeof plugin.view !== 'object' ||
        !('value' in plugin)
    ) {
        throw new Error('Plugin does not have expected shape.');
    }

    return plugin;
}

function makeNewFieldPlugin(metadata, registry) {
    const rawLabelText = (typeof metadata.memberName === 'string')
        ? metadata.memberName.split('_').map((s) => strings.capitalizeWord(s)).join(' ')
        : '';

    const labelText = (rawLabelText)
        ? (metadata.optional)
            ? rawLabelText
            : [rawLabelText, m('span.-fg-danger', ' *')]
        : '';

    const plugin = {
        value: null,
        metadata,
        registry,
        labelText,
        loadValue(v) {
            plugin.value = v;
        },
        encode() {
            return plugin.value;
        },
        maySubmit() {
            try {
                const v = plugin.encode();

                return (!v && plugin.metadata.optional) || v;
            } catch (e) {
                return false;
            }
        },
    };

    return plugin;
}

export function attributeFieldFactory(metadata, registry) {
    const ctor = selectConstructor(metadata);

    if (ctor) {
        return (
            validatePlugin(
                ctor(
                    makeNewFieldPlugin(metadata, registry),
                    metadata,
                    attributeFieldFactory)));
    } else {
        throw new Error(`No constructor for metadata: ${JSON.stringify(metadata)}`);
    }
}
