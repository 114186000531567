import { bem, logger, m, router, spa } from '#/browser-framework';
import { Avatar, Button, pointable } from '#/browser-framework/comps';
import { googleSignIn } from '#/browser-framework/firebase';

const btx = bem`
SessionMenu
    meta
    summary
    firstname
    logout
    email
`;

export const SessionMenu = {
    view({ attrs: { user } }) {
        if (!user) {
            return null;
        }

        if (user === 'guest') {
            return m('.SessionMenu',
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    async onpress() {
                        try {
                            await googleSignIn(spa.$window);
                            spa.redraw();
                        } catch (err) {
                            const whitelist = [
                                'auth/cancelled-popup-request',
                                'auth/popup-closed-by-user',
                            ];

                            // It's okay if the user closed the pop-up.
                            if (!whitelist.includes(err.code)) {
                                logger.error(err);
                                router.go('/error');
                            }
                        }
                    },
                }, 'Log in with Google'));
        }

        const {
            email,
            displayName,
            photoURL,
        } = user;

        return m(btx.block,
            m(Avatar, {
                headshot: photoURL,
                bgcolor: '#000',
                diameter: 48,
            }),
            m(btx.meta,
                m(btx.summary,
                    m(`span${btx.firstname}`,
                        displayName.split(' ')[0]),
                    ' (',
                    m(pointable(`span${btx.logout}`),
                        { onpointerup: () => spa.$window.firebase.auth().signOut() },
                        'Sign out'),
                    ')'),
                m(`span${btx.email}`, email)));
    },
};
