import EvidentWordmarkDarkSvg from '#/browser-framework/images/evident-wordmark-dark.svg';
import { m, router, tx } from '#/browser-framework';
import { Overlay, Spinner, pointable, Snackbar } from '#/browser-framework/comps';

import { SessionMenu } from './SessionMenu';
import { Counter } from './Counter';


function routeToPageTitle(route) {
    return ({
        '/rps': 'Relying Parties',
        '/ido': 'IDO Details',
        '/rps/list': 'Relying Parties',
        '/rps/create': 'RP Creation',
        '/rps/edit': 'RP Creation',
    }[route] || 'Operations Dashboard');
}


const JunctionNavItem = {
    view({attrs: {pendingRoute, route}, children}) {
        return m(pointable(), {onpointerdown: () => router.go(route)},
            m(tx('.JunctionNavItem', {selected: pendingRoute.startsWith(route)}), children));
    },
};



export const LargeFormatLayout = {
    view({attrs: spa, children}) {
        const {
            user,
            isUserAdmin,
            isUserContractor,
            verifications,
            dialogComp,
            dialogState,
        } = spa.viewModel;

        const pendingRoute = router.here();
        const hideOpsElements = (!user || user === 'guest');

        const link = (route, ...kids) =>
            m(JunctionNavItem, {pendingRoute, route}, ...kids);

        return m('.Junction',
            m('.Junction__brand', m('img', {
                src: EvidentWordmarkDarkSvg,
                alt: 'EvidentId Logo',
            })),
            m('.Junction__bar',
                m('h1.Junction__location',
                    (hideOpsElements)
                        ? ''
                        : routeToPageTitle(pendingRoute)),
                m('.Junction__session',
                    m(SessionMenu, {user}))),
            (hideOpsElements)
                ? m('.Junction__navigation')
                : m('.Junction__navigation',
                    (isUserAdmin) && link('/rps', 'RP Creation'),
                    (isUserAdmin || isUserContractor) &&
                        link('/verifications', 'Manual Verifications', m(Counter, verifications.lookup.size)),
                    (isUserAdmin) && link('/ido', 'IDO Details')),
            m('.Junction__panel',
                m('main.Junction__content',
                    (children.length > 0)
                        ? children
                        : m(Spinner))),
            (dialogComp) && m(Overlay, m(dialogComp, dialogState)),
            m(Snackbar, spa.viewModel.snackbar, spa.viewModel.snackbar.content),
        );
    },
};
