import { bem, logger, m, spa } from '#/browser-framework';
import { purecomp } from '#/browser-framework/vcomps';
import { Spinner } from '#/browser-framework/comps';

import { attributeFieldFactory } from '#/ops-facing/fields';
import { idoAttributeDisplayFactory } from '#/ops-facing/idoAttributes';
import { RequestInfo } from '#/ops-facing/views/RequestInfo';
import { fetchRecordData } from '#/ops-facing/junctionService';

const LoadingMore = purecomp(() =>
    m('.LoadingMore',
        m('p', 'More attachments are loading... Please wait.'),
        m('.LoadingMore__indicator', m(Spinner))));


const EXCLUDED_TYPES = [
    'BinaryData',
];

const genBem = bem`GeneratedInterface
    loadingMore
    attachments
    form
`;

export const GeneratedInterface = purecomp(({
    record: {
        attachedAttributes,
        recordSpec,
        fields,
        vowOtherData,
    }}) => {
    const attachedAttributesView = (attachedAttributes.length === 0)
        ? m('p', 'There are no attachments to show')
        : attachedAttributes
            .filter((value) => value)
            .map((a, key) => m(a.view, { key, a }));

    return m(genBem.block,
        m(genBem.form,
            fields.map((f, key) =>
                m(f.view, { key, f }))),
        m(genBem.attachments,
            m(RequestInfo, recordSpec),
            (vowOtherData) && m(genBem.loadingMore, m(LoadingMore)),
            attachedAttributesView));
});

export function getParentVerifications(verifications, recordSpecType) {
    const records = verifications
        .filter(({ recordType }) =>
            recordType !== recordSpecType)
        .map((otherSpec) => fetchRecordData(otherSpec));
    return Promise.all(records);
}

export const createIdoAttributeComponents = function(records) {
    records
        .reduce((previousValues, record) => previousValues.concat(record.values), [])
        .filter((value) => !EXCLUDED_TYPES.includes(value.content.$objectType))
        .map((v) => idoAttributeDisplayFactory(v));
};


export const GeneratedInterfaceModel = (verification, recordSpec, recordData) => {
    const iface = {
        recordSpec,
        submitted: false,
        authorizationId: recordSpec.authorizationId,
        recordType: recordSpec.recordType,
        $objectType: recordData.dataType,
        attachedAttributes: recordData.values
            .filter((value) => value)
            .map((v) => idoAttributeDisplayFactory(v)),
        fields: recordData.resolvedMembers.map((fm) =>
            attributeFieldFactory(fm, recordData.registry)),
        view: GeneratedInterface,
    };

    iface.vowOtherData = (spa.viewModel.isUserAdmin)
        ? getParentVerifications(verification.parent.verifications, recordSpec.recordType)
            .then((otherRecordData) => {
                iface.vowOtherData = null;
                iface.attachedAttributes = (recordData.values)
                    ? recordData.values
                        .map((v) => idoAttributeDisplayFactory(v))
                        .concat(createIdoAttributeComponents(otherRecordData))
                    : [];
                spa.redraw();
            })
            .catch((e) => {
                iface.vowOtherData = null;
                spa.viewModel.snackbar.display('Data loading error', false);
                logger.error(e);
            })
        : null;

    iface.maySubmit = () =>
        iface.fields.every((f) =>
            f.maySubmit());

    iface.encode = () => iface.fields.reduce((p, c) =>
        Object.assign(p, {
            [c.metadata.memberName]: c.encode(),
        }), { $objectType: iface.$objectType });

    iface.getFieldByMemberName = (mn) =>
        iface.fields.find(({ metadata: { memberName } }) =>
            mn === memberName);

    return iface;
};
