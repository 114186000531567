import { m } from '#/browser-framework';


export const Counter = {
    view({
        children,
    }) {
        return m('.Counter', m('span', children));
    },
};
