import { bem, events, m, tx } from '#/browser-framework';
import { Collapsible, LabeledCheckbox, Tooltip } from '#/browser-framework/comps';

const alertsBem = bem`
    VerificationFailures
        intro
        line
        description
        descriptionText
        tooltipTitle
        tooltipBody
`;

export const INTRO_TEXT = 'Verify, that the following issues have been investigated:';

export const AcuantErrorsComponent = {
    _record: {},
    _ensured: {},
    _hasAlerts: false,
    _hasUnresolvedAlerts: false,

    view({ attrs: { open, record }}) {
        this._setRecordData(record);
        return this._hasAlerts
            ? m(Collapsible, {
                inAlertState: this._hasUnresolvedAlerts,
                open,
                label: 'Verification Failures',
                ontoggle: (prevState) => events.emit('toggle-verification-failures', prevState),
            }, m(tx(alertsBem.block, {
                '.VerificationFailures--alerts': this._hasUnresolvedAlerts,
            }), m(alertsBem.intro, INTRO_TEXT), ...this._getAlertsLines()))
            : null;
    },

    _setRecordData(record) {
        this._record = record;
        this._hasAlerts = Boolean(this._record.externalApiAlerts.length);
        this._updateAlertsResolveState();
    },

    _updateAlertsResolveState() {
        const alertsEnsuredCount = Object.values(this._ensured)
            .filter((selection) => Boolean(selection))
            .length;
        this._hasUnresolvedAlerts = alertsEnsuredCount !== this._record.externalApiAlerts.length;
    },

    _getAlertsLines() {
        return this._record.externalApiAlerts
            .map((alert, index) => {
                const checkboxId = index;
                const tooltip = this._generateTooltip(alert.Disposition, alert.Actions);
                return m(alertsBem.line,
                    m(LabeledCheckbox, {
                        state: this._ensured[checkboxId],
                        onchange: () => this._updateErrorEnsureInfo(checkboxId),
                    },
                    m(alertsBem.description, m(`span${alertsBem.descriptionText}`, alert.Disposition), tooltip)));
            });
    },

    _generateTooltip(title, description) {
        return m(Tooltip,
            m(alertsBem.tooltipTitle, title),
            m(alertsBem.tooltipBody, description)
        );
    },

    _updateErrorEnsureInfo(id) {
        this._ensured[id] = !this._ensured[id];
        this._updateAlertsResolveState();
        this._record.externalApiAlertsResolved = !this._hasUnresolvedAlerts;
    },
};
