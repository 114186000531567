import json from '#/universal-framework/json';
import { errors, events, logger, router, spa, strings } from '#/browser-framework';
import { lets } from '#/universal-framework/functions';
import { Spinner } from '#/browser-framework/comps';
import { bindFirebase } from '#/browser-framework/firebase';

import { LargeFormatLayout } from '#/ops-facing/views/Layouts';

import RelyingPartiesOverview from '#/ops-facing/views/RelyingPartiesOverview';
import OverviewVerifications from '#/ops-facing/views/OverviewVerifications';
import LoginInstructions from '#/ops-facing/views/LoginInstructions';
import { CreateRelyingPartyForm } from '#/ops-facing/views/CreateRelyingPartyForm';
import { EditRelyingPartyForm } from '#/ops-facing/views/EditRelyingPartyForm';
import { IdoSearch } from '#/ops-facing/views/IdoSearch';
import { IdoDetail } from '#/ops-facing/views/IdoDetail';

import { mainController } from '#/ops-facing/controllers/mainController';
import { UnauthorizedJunctionUser, NotFound } from '#/ops-facing/controllers/errorController';

import { installBackChannel, stopListening } from '#/ops-facing/pdf417BackChannel';

import '#/ops-facing/styles/screen.less';

let junction;


const guard = (fn) => (...args) => {
    junction.referrer = router.here();

    // Stop existing PDF417 polls on navigation
    stopListening();

    if (!junction.user) {
        return 'div';
    } else if (junction.user === 'guest' || (!junction.isUserAdmin && !junction.isUserContractor)) {
        router.go(`/error/${UnauthorizedJunctionUser().reason}`);
    } else {
        return fn(...args);
    }
};

const adminsOnly = (fn) =>
    guard((...args) =>
        ((junction.isUserAdmin)
            ? fn(...args)
            : router.go(`/error/${NotFound.reason}`)));

const redirect = (r) => () => router.go(r, { replace: true });

router.on('Error', '/error', () =>
    junction.errors.showError(errors.exception()));

router.on('Error', '/error/:code', ([code]) =>
    junction.errors.showError(errors.lookup(code)()));

router.on('Log in', '/login', () =>
    spa.setView(LoginInstructions, {
        layout: LargeFormatLayout,
    }));


router.on('Home', '/', redirect('/login'));
router.on('Operations', '/rps/list', redirect('/rps'));
router.on('Operations', '/rps', adminsOnly(() => {
    spa.setView(RelyingPartiesOverview, {
        layout: LargeFormatLayout,
    });
}));


// This handler is responsible for searching available manual verifications
router.on('Verifications', '/verifications', guard(() =>
    spa.setView(OverviewVerifications, { layout: LargeFormatLayout })));

// Responsible for loading and resolving individual manual verifications with user input.
router.on('Verification', '/verifications/:args...', guard((args) =>
    lets(args, (localPart, recordType, ...rest) =>
        import('./manualVerificationResolver')
            .then(({ focusVerification }) =>
                focusVerification(localPart, recordType, rest)))));

router.on('Create RP', '/rps/create', adminsOnly(() => {
    const { rps: { staged } } = junction;
    const { fields } = staged;

    staged.rpExists = false;
    staged.error = null;

    fields.displayName.value =
        fields.entityName.value =
        fields.supportEmail.value =
        fields.admins.value =
        fields.recipients.value = '';

    fields.displayName.showError =
        fields.entityName.showError =
        fields.supportEmail.showError =
        fields.admins.showError = false;

    fields.notifyPrefs.value = {
        enabled: {
            rpCompleteRequest: true,
            rpDatumShare: false,
            idoNewRequest: true,
            idoAddlAttrs: true,
            idoReadyToShare: true,
        },
        reminder: {
            rpCompleteRequest: true,
            rpDatumShare: false,
            idoNewRequest: true,
            idoAddlAttrs: true,
            idoReadyToShare: true,
        },
    };

    staged.synchronize().then(() => {
        spa.setView(CreateRelyingPartyForm, {
            layout: LargeFormatLayout,
        });
    });
}));

router.on('Edit RP', '/rps/edit/:name', adminsOnly(([name]) => {
    junction.rps
        .requestRps()
        .then(() => {
            const rp = junction.rps.focusedRp = junction.rps.list.find((({ entityName }) => entityName === name));

            if (rp) {
                const { staged } = junction.rps;
                const { recipients, sendNotify, sendReminder } = rp.notificationConfig;
                const { fields } = staged;

                staged.rpExists = true;
                staged.error = null;

                fields.displayName.value = rp.displayName;
                fields.entityName.value = rp.entityName;
                fields.supportEmail.value = rp.supportEmail;
                fields.admins.value = strings.alphabetize(rp.admins).join('\n');

                fields.recipients.value = strings.alphabetize(recipients).join('\n');
                fields.notifyPrefs.value = {
                    enabled: {
                        rpCompleteRequest: Boolean(sendNotify.relyingParty.completedRequest),
                        rpDatumShare: Boolean(sendNotify.relyingParty.datumShared),
                        idoAddlAttrs: Boolean(sendNotify.idOwner.newAttributesRequired),
                        idoNewRequest: Boolean(sendNotify.idOwner.newRequest),
                        idoReadyToShare: Boolean(sendNotify.idOwner.readyToShare),
                    },
                    reminder: {
                        rpCompleteRequest: Boolean(sendReminder.relyingParty.completedRequest),
                        rpDatumShare: Boolean(sendReminder.relyingParty.datumShared),
                        idoAddlAttrs: Boolean(sendReminder.idOwner.newAttributesRequired),
                        idoNewRequest: Boolean(sendReminder.idOwner.newRequest),
                        idoReadyToShare: Boolean(sendReminder.idOwner.readyToShare),
                    },
                };

                const atIndex = staged.authTypes.findIndex((at) => at === rp._objective.userAuthType);

                staged.authTypeIndex = (atIndex > -1) ? atIndex : 0;
                staged.enableVerifyApi = rp._objective.verifyApiEnabled;
                staged.googleClientId = (rp._objective.userAuthType === 'googleauth')
                    ? rp._objective.userAuthAudience
                    : '';

                spa.setView(EditRelyingPartyForm, {
                    layout: LargeFormatLayout,
                });
            } else {
                router.go('/rps/list');
            }
        });
}));

router.on('IDO search', '/ido', adminsOnly(() => {
    spa.setView(IdoSearch, {
        layout: LargeFormatLayout,
    });
}));

router.on('IDO detail', '/ido/:idoId', adminsOnly(([idoId]) => {
    const { idoEditor } = spa.viewModel;
    spa.setView(Spinner, { layout: LargeFormatLayout });

    idoEditor
        .selectIdoById(idoId)
        .then(() => spa.setView(IdoDetail, { layout: LargeFormatLayout }))
        .catch((e) => {
            logger.error(e);
            router.go(`/error/${e.reason || 'unexpected'}`);
        });
}));

spa.start(() => {
    installBackChannel();

    mainController()
        .then((main) => {
            junction = spa.viewModel = main;

            events.on('firebase-auth-new-state', junction.attachSession);

            return bindFirebase(
                spa.$window,
                events,
                json.object(deploy.WEB_PUBLIC_RPWEB_FIREBASE_CONFIG));
        })
        .then(() => {
            router.start({
                titleBrand: 'Evident',
                titleSeparator: ' - ',
                fallback() {
                    router.go('/');
                },
            });
        });
});
