import { bem, m } from '#/browser-framework';
import { purecomp } from '#/browser-framework/vcomps';
import { factory} from '#/universal-framework/objects';

import { Collapsible, Button } from '#/browser-framework/comps';
import JsonDump from '#/browser-framework/comps/JsonDump';

const civilMV = bem`civilMV
    header
    body
    detail
    detailKey
    detailValue
    buttons
    caseList
    case--active
    case--inactive
    readOnly
    leftCol
    rightCol`;

// this interface serves allows the operator to filter out any charges that do not deserve IA treatment
const policyMatchButton = purecomp(({isPolicyMatch, index, setMatch, value}, children) => (
    isPolicyMatch === value
        ? m(Button, {
            flags: {
                primary: true,
                disabled: true,
                round: true,
            },
        }, children)
        : m(Button, {
            flags: {
                primary: true,
                round: true,
            },
            onpress: () => setMatch(index, value),
        }, children)));

const requestView = purecomp(({requestDetails}) => {
    return m(civilMV.readOnly,
        m(civilMV.header, 'Request Info'),
        m(civilMV.body,
            m(civilMV.detail,
                m(civilMV.detailKey, 'IDO Email'),
                m(civilMV.detailValue, requestDetails.email || m('em', 'No email found</em>'))),
            m(civilMV.detail,
                m(civilMV.detailKey, 'Summary'),
                m(civilMV.detailValue, requestDetails.summary || m('em', 'No summary found</em>'))),
            m(civilMV.detail,
                m(civilMV.detailKey, 'Description'),
                m(civilMV.detailValue, requestDetails.description || m('em', 'No description found')))));
});

const idoView = purecomp(({idoDetails}) => {
    return m(civilMV.readOnly,
        m(civilMV.header, 'Identity Owner Details'),
        m(civilMV.body,
            m(civilMV.detail,
                m(civilMV.detailKey, 'Name'),
                m(civilMV.detailValue, idoDetails['core.fullname']
                    ? idoDetails['core.fullname'].middle
                        ? `${idoDetails['core.fullname'].first} ${idoDetails['core.fullname'].middle} ${idoDetails['core.fullname'].last}`
                        : `${idoDetails['core.fullname'].first} ${idoDetails['core.fullname'].last}`
                    : 'Name not found')),
            m(civilMV.detail,
                m(civilMV.detailKey, 'Address'),
                m(civilMV.detailValue, JsonDump({value: idoDetails['core.address.fulladdress']}) || m('em', 'Address not found'))),
            m(civilMV.detail,
                m(civilMV.detailKey, 'Date of Birth'),
                m(civilMV.detailValue, idoDetails['core.dateofbirth']
                    ? `${idoDetails['core.dateofbirth'].month}/${idoDetails['core.dateofbirth'].day}/${idoDetails['core.dateofbirth'].year}`
                    : m('em', 'Date of Birth not found')))));
});

// In this view an Ops agent can select the civil cases that deserve an IA
// They must explicitly decide whether each case will be in the IA or not
export const caseList = purecomp(({cases, collapsibleState, policyMatchingState, toggleVisibility, setMatch}) => (
    cases.map((caseObj, caseIndex) => (
        m((typeof policyMatchingState[caseIndex] === 'boolean'
            ? policyMatchingState[caseIndex] === true
                ? '.civilMV__case--active'
                : '.civilMV__case--inactive'
            : '.civilMV__case'),
        m(Collapsible, {
            open: collapsibleState[caseIndex],
            label: `Case ${caseIndex + 1} of ${cases.length}`,
            ontoggle: () => toggleVisibility(caseIndex),
        },
        m(civilMV.buttons,
            m(policyMatchButton, {
                isPolicyMatch: policyMatchingState[caseIndex],
                index: caseIndex,
                setMatch,
                value: true}, 'Match'),
            m(policyMatchButton, {
                isPolicyMatch: policyMatchingState[caseIndex],
                index: caseIndex,
                setMatch,
                value: false}, 'Doesn\'t Match')),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Disposition'),
            m(civilMV.detailValue, caseObj.civil_offense.disposition
                ? caseObj.civil_offense.disposition
                : m('em', 'No Disposition found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Disposition Date'),
            m(civilMV.detailValue, caseObj.civil_offense.disposition_date
                ? `${caseObj.civil_offense.disposition_date.month}/${caseObj.civil_offense.disposition_date.day}/${caseObj.civil_offense.disposition_date.year}`
                : m('em', 'No Disposition Date found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Offense:'),
            m(civilMV.detailValue, caseObj.civil_offense.offense
                ? caseObj.civil_offense.offense
                : m('em', 'No Offense found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Offense Severity'),
            m(civilMV.detailValue, caseObj.civil_offense.offense_severity
                ? caseObj.civil_offense.offense_severity
                : m('em', 'No Offense Severity Information found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Court Filed In'),
            m(civilMV.detailValue, caseObj.civil_offense.court_filed_in
                ? caseObj.civil_offense.court_filed_in
                : m('em', 'No Court Information available'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Docket Number'),
            m(civilMV.detailValue, caseObj.civil_offense.docket_number
                ? caseObj.civil_offense.docket_number
                : m('em', 'No Docket Number Found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Docket Date'),
            m(civilMV.detailValue, caseObj.civil_offense.docket_date
                ? `${caseObj.civil_offense.docket_date.month}/${caseObj.civil_offense.docket_date.day}/${caseObj.civil_offense.docket_date.year}`
                : m('em', 'No docket date found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Offense Date'),
            m(civilMV.detailValue, caseObj.civil_offense.offense_date
                ? `${caseObj.civil_offense.offense_date.month}/${caseObj.civil_offense.offense_date.day}/${caseObj.civil_offense.offense_date.year}`
                : m('em', 'No offense date found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Plaintiff'),
            m(civilMV.detailValue, typeof caseObj.civil_offense.plaintiff === 'object'
                ? caseObj.civil_offense.plaintiff.middle
                    ? `${caseObj.civil_offense.plaintiff.first} ${caseObj.civil_offense.plaintiff.middle} ${caseObj.civil_offense.plaintiff.last}`
                    : `${caseObj.civil_offense.plaintiff.first} ${caseObj.civil_offense.plaintiff.last}`
                : caseObj.civil_offense.plaintiff
                    ? caseObj.civil_offense.plaintiff
                    : m('em', 'Plaintiff Name not found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Defendent'),
            m(civilMV.detailValue, typeof caseObj.civil_offense.plaintiff === 'object'
                ? caseObj.civil_offense.defendant.middle
                    ? `${caseObj.civil_offense.defendant.first} ${caseObj.civil_offense.defendant.middle} ${caseObj.civil_offense.defendant.last}`
                    : `${caseObj.civil_offense.defendant.first} ${caseObj.civil_offense.defendant.last}`
                : caseObj.civil_offense.defendant
                    ? caseObj.civil_offense.defendant
                    : m('em', 'Defendent name not found'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Verified By'),
            m(civilMV.detailValue, caseObj.method_verified.matched_by
                ? caseObj.method_verified.matched_by
                : m('em', 'Unknown verification method'))),
        m(civilMV.detail,
            m(civilMV.detailKey, 'Comments'),
            m(civilMV.detailValue, caseObj.civil_offense.comments
                ? caseObj.civil_offense.comments
                : m('em', 'No Comments')))))))));

export const CivilRecordInterface = purecomp(({record}) => {
    return m(civilMV.block,
        m(civilMV.leftCol,
            m(caseList, {
                cases: record.civilCharges,
                collapsibleState: record.collapsibleState,
                policyMatchingState: record.policyMatchingState,
                setMatch: record.setMatch,
                toggleVisibility: record.toggleVisibility,
            })),
        m(civilMV.rightCol,
            m(requestView, {requestDetails: record.recordSpec}),
            m(idoView, {idoDetails: record.recordData.values
                .reduce((obj, idoDetail) => {
                    obj[idoDetail.attributeType] = idoDetail.content;
                    return obj;
                }, {})})));
});

export default (verification, recordSpec, recordData) => {
    const civilCharges = recordData.values
        .filter((record) => (record.attributeType.includes('civil.')))
        .filter((record) => (record.content.cases.length))
        .map((civilCharge) => (civilCharge.content.cases))
        .flat(1);

    return factory((iface) => ({
        submitted: false,
        view: CivilRecordInterface,
        recordSpec,
        recordData,
        civilCharges,
        verification,
        collapsibleState: civilCharges.map(() => true),
        policyMatchingState: civilCharges.map(() => null),
        toggleVisibility: (index) => {
            iface.collapsibleState[index] = !iface.collapsibleState[index];
        },
        setMatch: (index, value) => {
            iface.policyMatchingState[index] = value;
        },
        maySubmit: () => {
            const remainingCases = iface.policyMatchingState.filter((_case) => (typeof _case !== 'boolean'));
            return !remainingCases.length;
        },
        encode: () => {
            return {
                $objectType: 'CivilProfileRecord',
                civil_matches: civilCharges.filter((_case, index) => (iface.policyMatchingState[index])),
                status: Boolean(iface.policyMatchingState.filter((_case) => (_case)).length),
            };
        },
    }));
};
