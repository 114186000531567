import { m } from '#/browser-framework';

const Form = {
    view({
        attrs: {
            f,
        },
    }) {
        const { dataType } = f.metadata;

        return m('.PrimitiveDump',
            m('h2', f.labelText, ` (Generated from type: ${dataType})`),
            f.kids.map((k, key) => {
                return m(k.view, {key, f: k});
            }));
    },
};


// A fallback plugin that attempts to take an unknown record type
// and render it as a flattened list of primitives.
export default function PrimitiveDump(plugin, metadata, factory) {
    plugin.kids = metadata.members.map((p) => factory(p, plugin.registry));
    plugin.view = Form;

    plugin.getFieldByMemberName = function getFieldByMemberName(mn) {
        return plugin.kids.find(({ metadata: { memberName } }) => memberName === mn);
    };

    plugin.loadValue = function loadValue(d) {
        for (const k of plugin.kids) {
            k.loadValue(d[k.metadata.memberName]);
        }
    };

    plugin.isBlank = function isBlank() {
        return plugin.kids.every((k) => !k.encode());
    };

    plugin.maySubmit = function maySubmit() {
        // The implication of this expression that if any one member has a value,
        // all members are validated.

        return (
            // If this field is optional and all members are blank...
            (plugin.metadata.optional && plugin.isBlank()) ||

            // ...or if all members are good to submit.
            plugin.kids.every((k) => k.maySubmit())
        );
    };

    plugin.encode = () => {
        return (plugin.isBlank())
            ? null
            : plugin.kids.reduce((p, k) => {
                p[k.metadata.memberName] = k.encode();

                return p;
            }, {
                $objectType: metadata.dataType,
            });
    };

    return plugin;
}
