import { m, router, spa } from '#/browser-framework';
import { Spinner, pointable } from '#/browser-framework/comps';

import { SearchBar } from './SearchBar';

const Instructions = {
    view() {
        return m('article',
            m('p',
                'This screen can pull up one IDO at a time. ',
                'You can search for an IDO using any one of the below:'),
            m('ul',
                m('li', 'Attribute sharing agreement (ASA) ID'),
                m('li', 'ID Owner ID'),
                m('li', 'ID Owner email'),
                m('li', 'Relying party request ID')),
            m('em',
                'All times in returned records are shown as ',
                m('strong', 'local time'),
                ' unless your system is set up to do otherwise.'));
    },
};

const NoResults = {
    view() {
        return m('p', 'No results.');
    },
};

const SelectIdoById = {
    view({
        attrs: {
            idoEditor,
        },
    }) {
        return m('table.OperationsTable',
            m('thead',
                m('th', 'IDO id')),
            m('tbody',
                idoEditor.searchMatches.map((id, key) =>
                    m(pointable('tr'), {
                        key,
                        onpointerup() {
                            router.go(`/ido/${spa.$window.encodeURIComponent(id)}`);
                        },
                    }, m('td', id)))));
    },
};


export const _componentFromSearchState =
    (idoEditor) =>
        ((idoEditor.isSearching())
            ? Spinner
            : (idoEditor.hasSearchResults())
                ? (idoEditor.searchMatches.length === 0)
                    ? NoResults
                    : SelectIdoById
                : Instructions);


export const IdoSearch = {
    view({
        attrs: {
            viewModel: {
                idoEditor,
            },
        },
    }) {
        if (!idoEditor) {
            return null;
        }

        return m('.Overview.IdoDetail',
            m(SearchBar, {
                value: idoEditor.searchText,
                lookBusy: idoEditor.isSearching(),
                placeholder: 'RPR ID, ASA ID, IDO ID or IDO email',
                oninput(e) {
                    idoEditor.updateSearchText(e.target.value);
                },
            }),
            (idoEditor.errors.size > 0) && m('.-fg-danger',
                Array.from(idoEditor.errors).map(([, e]) => m('p', e.message))),
            m(_componentFromSearchState(idoEditor), {
                idoEditor,
            }));
    },
};
