import debounce from 'debounce';

import { exception } from '#/universal-framework/errors';
import { timestampObjectToLocaleString } from '#/universal-framework/dates';
import { events, spa, router } from '#/browser-framework';
import { optionset } from '#/browser-framework/behaviors';

import { idoModel } from '#/ops-facing/models/idoModel';
import {
    deleteAttributeDatum,
    deleteRequest,
    timeoutRequest,
    getIdoDetails,
    getMacros,
    runMacro,
    searchIdos,
} from '#/ops-facing/junctionService';

import { requestController } from './requestController';


const RETRY_MACRO_NAME = 'retryAttributeSharingFulfilment';

export const datumSet = (attributeDataArray) => ({
    deleteSelection: optionset({
        dirty: false,
        initialState: attributeDataArray
            .reduce(
                (p, { id }) => Object.assign(p, { [id]: false }),
                {}),
    }),
    attributeDataArray:
        attributeDataArray
            .sort(({ issued_at: { timestamp: a } }, { issued_at: { timestamp: b } }) => b - a)
            .map((ad) => ({
                id: ad.id,
                type: ad.attribute_type,
                issuedAtText: timestampObjectToLocaleString(ad.issued_at),
                sourceText: ad.attribute_source,
            })),
});

export function idoEditor() {
    const iface = {};

    iface._request = null;
    iface._focusedIdoId = null;
    iface.searchMatches = null;
    iface.searchText = '';
    iface.detail = null;
    iface.errors = new Map();

    iface.selectIdoById = (idOwnerId) => {
        iface._focusedIdoId = idOwnerId;

        return getIdoDetails(idOwnerId)
            .then((serviceData) => iface.loadModel(serviceData));
    };

    iface._disambiguateIdoSelection = (idoArray) => {
        iface.searchMatches = idoArray;

        if (idoArray.length === 1) {
            router.go(`/ido/${encodeURIComponent(idoArray[0])}`);
        }
    };

    iface.searchForIdo = debounce(() => {
        iface.discardModel();

        if (iface._request && iface._request.abort) {
            iface._request.abort();
        }

        iface.errors.delete('no-results');
        spa.redraw();

        iface._request = searchIdos(iface.searchText)
            .then((idoArray) => iface._disambiguateIdoSelection(idoArray))
            .catch((e) => iface.errors.set(e.code, e))
            .then(spa.redrawAfter(() => {
                iface._request = null;

                return iface.loadMacros();
            }));

        spa.redraw();

        return iface._request;
    }, 800);

    iface.loadMacros = spa.redrawAfter(() => getMacros()
        .then((macros) => {
            const ind = macros.indexOf(RETRY_MACRO_NAME);

            if (ind > -1) {
                macros.splice(ind, 1);
            }

            iface.commands = {
                commands: macros,
                execute(macroName) {
                    events.emit('run-macro', macroName);
                },
            };
        }));

    iface.refresh = spa.redrawAfter(() => {
        iface._refreshing =
            getIdoDetails(iface.detail.id)
                .then((serviceData) =>
                    iface.loadModel(serviceData))
                .catch(() => null)
                .then(() => {
                    iface._refreshing = null;
                });

        return iface._refreshing;
    });

    iface.executeMacro = (macroName, args, { refresh = false } = {}) =>
        runMacro(iface.detail.id, macroName, args)
            .then(() => ((refresh) ? iface.refresh() : null));

    iface.loadModel = spa.redrawAfter((serviceData) => {
        iface.requests = serviceData.relying_party_requests.map(requestController);
        iface.detail = idoModel(serviceData);
        iface.datums = datumSet(serviceData.attribute_data);
    });

    iface.discardModel = spa.redrawAfter(() => {
        iface.detail =
        iface.commands =
        iface.datums =
        iface.requests = null;
    });

    iface.updateSearchText = (text) => {
        iface.searchText = text;
        iface.searchForIdo();
    };

    iface.isSearching = () => Boolean(iface._request);
    iface.isRefreshing = () => Boolean(iface._refreshing);
    iface.hasSearchResults = () => Array.isArray(iface.searchMatches);
    iface.isIdoLoaded = () => Boolean(iface.detail);

    iface.retrySharingFulfilments = (asaIds) => {
        if (asaIds.length > 0) {
            iface.errors.delete('cannot-retry');

            return Promise
                .all(asaIds.map((asaId) =>
                    iface.executeMacro(RETRY_MACRO_NAME, { asaId }, { refresh: false })))
                .catch((e) =>
                    iface.errors.set('cannot-retry', e))
                .then(iface.refresh);
        }

        return Promise.reject(exception({
            code: 'nothing-to-retry',
            message: 'Nothing to retry',
        }));
    };

    iface.deleteAttributeDatums = (attrIds) => {
        if (attrIds.length > 0) {
            iface.errors.delete('cannot-delete');

            return Promise
                .all(attrIds.map((attrId) =>
                    deleteAttributeDatum(iface.detail.id, attrId)))
                .catch((e) =>
                    iface.errors.set('cannot-delete', e))
                .then(iface.refresh);
        }

        return Promise.resolve();
    };

    iface.timeoutRequest = (requestId) => {
        return timeoutRequest(requestId)
            .catch((e) => iface.errors.set('cannot-timeout', e))
            .then(iface.refresh);
    };

    iface.deleteRequest = (requestId) => {
        iface.errors.delete('cannot-delete');

        return deleteRequest(requestId)
            .catch((e) => iface.errors.set('cannot-delete', e))
            .then(iface.refresh);
    };

    return iface;
}
