import { bem, m, router, tx } from '#/browser-framework';
import { Button, Named, pointable, TranscribedError } from '#/browser-framework/comps';
import { SearchBar } from './SearchBar';
import primarySpinner from '#/browser-framework/images/primarySpinner.svg';


const OverviewBem = bem`
    Overview
    toolbar
    actions
    sorticon
    spinner
`;

export default {
    view({
        attrs: {
            viewModel: {
                rps,
            },
        },
    }) {
        return m(OverviewBem.block,
            m(OverviewBem.toolbar,
                m(SearchBar, {
                    value: rps.searchText,
                    oninput(e) {
                        rps.searchText = e.target.value.trimStart();
                        rps.loading = true;
                        rps.debouncedRequestRps();
                    },
                }),
                m(OverviewBem.actions,
                    m(Button, {
                        onpress: () => router.go('/rps/create'),
                        flags: {
                            round: true,
                            primary: true,
                        },
                    }, 'Create New RP'))),
            rps.searchError
                ? m(TranscribedError, { error: rps.searchError })
                : (rps.loading)
                    ? m(`img${OverviewBem.spinner}`, { src: primarySpinner})
                    : (rps.list.length > 0)
                        ? m('table.OperationsTable',
                            m('thead',
                                m('tr',
                                    m(pointable('th'), {
                                        onpointerup() {
                                            rps.setSortAscending(!rps.sortRpsAscending);
                                        },
                                    },
                                    'RP Name',
                                    m(`span${OverviewBem.sorticon}`,
                                        m(Named.Icon, {name: (rps.sortRpsAscending)
                                            ? 'sort-asc'
                                            : 'sort-desc'}))),
                                    m('th', 'Status'))),
                            m('tbody',
                                (rps.loading && rps.length === 0)
                                    ? null
                                    : (rps.list.length === 0)
                                        ? m('tr', m('td', {colspan: 2}, m('p', 'No results found')))
                                        : rps.list.map(({displayName, entityName, status}, key) => {
                                            const classification = tx({
                                                'fg-success': status === 'Active',
                                                'fg-danger': status === 'Pending',
                                            });

                                            return m(pointable('tr'), {
                                                key,
                                                onpointerup(e) {
                                                    e.preventDefault();
                                                    rps.focusRp(entityName);
                                                },
                                            },
                                            m('td', displayName),
                                            m('td', m(classification, status)));
                                        })))
                        : null);
    },
};
