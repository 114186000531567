import { debounce, events, spa, router } from '#/browser-framework';
import { getKeyGivenPreference } from '#/universal-framework/maps';
import { factory } from '#/universal-framework/objects';

import { getVerifications, fetchRecordData } from '#/ops-facing/junctionService';
import { recordInterfaceFactory } from '#/ops-facing/records/RecordInterfaceFactory';

export const getLastSubmissionTime = (verifications) =>
    Math.max(...verifications.map(
        ({ createdAt }) => createdAt.timestamp));

export const idoIdToLocalPart = (idoId) =>
    idoId.split('@')[0];

export const buildRecordLookUpTable =
    (result) =>
        new Map(result.verifications.reduce((p, record) =>
            p.concat([[record.recordType, {
                parent: result,
                record,
            }]]), []));

export const buildVerificationLookUpTable =
    (results = []) =>
        new Map(results.reduce((p, r) =>
            p.concat([[idoIdToLocalPart(r.ido), buildRecordLookUpTable(r)]]), []));

export const spendVerification =
    (lookupTable, localPart, recordType) =>
        new Map(
            Array
                .from(lookupTable)
                .map(([lp, rcs]) => [
                    lp,
                    new Map(
                        Array
                            .from(rcs)
                            .filter(([rt]) =>
                                (localPart !== lp || rt !== recordType))),
                ])
                .filter(([, rcs]) =>
                    rcs.size > 0));

export const getNextVerificationSelection =
    (lookupTable, preferredLocalPart, preferredRecordType) => {
        const nextIdoLocalPart = getKeyGivenPreference(
            lookupTable,
            preferredLocalPart);

        if (nextIdoLocalPart) {
            const nextRecordType = getKeyGivenPreference(
                lookupTable.get(nextIdoLocalPart),
                preferredRecordType);

            return (nextRecordType)
                ? [nextIdoLocalPart, nextRecordType]
                : undefined;
        }

        return undefined;
    };


export const _verificationControllerImpl = (iface) => ({
    searchText: '',
    lookup: buildVerificationLookUpTable(),

    // This treats iface.lookup as the source of truth
    // for verifications so that finishing a verification
    // will look like the result gets removed from search results
    // without a second long GET call
    getResults: () => ((iface.lookup)
        ? Array.from(iface.lookup.values()).reduce((p, mp) =>
            p.concat([mp.values().next().value.parent]), [])
        : []),

    onChangeSearch: (newSearchText) => {
        iface.searchText = newSearchText;

        return iface.debouncedSearch(newSearchText);
    },

    goToNextVerification: () => {
        const [lp, rt] = router.procure().args.slice(0, 2);

        iface.lookup = spendVerification(iface.lookup, lp, rt);

        const result = getNextVerificationSelection(iface.lookup, lp, rt);

        router.go((result && result[0] === lp)
            ? `/verifications/${encodeURIComponent(result[0])}/${encodeURIComponent(result[1])}`
            : '/verifications');
    },

    debouncedSearch: debounce((newSearchText) =>
        iface.searchVerifications(newSearchText), 300),

    searchVerifications: spa.redrawAfter((searchText = '') => {
        iface.searching =
            getVerifications(searchText)
                .then((results) =>
                    results
                        .map((r) => Object.assign({
                            lastSubmissionUtcTimestamp: getLastSubmissionTime(r.verifications),
                        }, r))
                        .sort(({ lastSubmissionUtcTimestamp: a }, { lastSubmissionUtcTimestamp: b }) =>
                            (b - a)))
                .then((results) =>
                    Object.assign(iface, {
                        lookup: buildVerificationLookUpTable(results),
                        searching: null,
                    }));

        // To show loading indicator
        spa.redraw();

        return iface.searching;
    }),

    loadRecord: async (idoId, recordType, rest) => {
        const entry = iface.lookup
            .get(idoId)
            .get(recordType);

        if (entry && !entry.recordData) {
            entry.recordData = await fetchRecordData(entry.record);
        }
        return recordInterfaceFactory(entry, entry.record, entry.recordData, rest);
    },
});

export const verificationController = () =>
    factory(
        _verificationControllerImpl,
        events.prescribe((iface) => [
            ['record-submitted', iface.goToNextVerification],
        ]));
