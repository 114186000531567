import { lets } from '#/universal-framework/functions';
import { bem, m } from '#/browser-framework';
import { purecomp } from '#/browser-framework/vcomps';


export const Form = lets([bem`FullNameField inputs prefix first middle last suffix`],
    ({ block, prefix, inputs, first, middle, last, suffix }) =>
        purecomp(({ f }) => m(block,
            m('label', f.labelText),
            m(inputs,
                m(`input${prefix}`, {
                    placeholder: 'Ms., etc.',
                    value: f.prefix,
                    oninput: (e) => (f.prefix = e.target.value),
                }),
                m(`input${first}`, {
                    class: (f.errors.first) ? 'border-danger' : '',
                    value: f.first,
                    placeholder: 'First',
                    oninput: (e) => (f.first = e.target.value),
                    onblur: () => (f.showError('first')),
                }),
                m(`input${middle}`, {
                    value: f.middle,
                    placeholder: 'Middle',
                    oninput: (e) => (f.middle = e.target.value),
                }),
                m(`input${last}`, {
                    class: (f.errors.last) ? 'border-danger' : '',
                    value: f.last,
                    placeholder: 'Last',
                    oninput: (e) => (f.last = e.target.value),
                    onblur: () => (f.showError('last')),
                }),
                m(`input${suffix}`, {
                    value: f.suffix,
                    placeholder: 'Jr., etc.',
                    oninput: (e) => (f.suffix = e.target.value),
                })))));


export default function FullNameField(plugin) {
    plugin.view = Form;

    plugin.prefix =
    plugin.first =
    plugin.middle =
    plugin.last =
    plugin.suffix = '';

    plugin.showError = false;
    plugin.errors = {};

    plugin.showError = (pluginProp) => {
        plugin.errors[pluginProp] = !plugin[pluginProp];
    };

    // Should be () => true because https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/
    // but circumstances around IDs and vendor logic in background tests led to this implementation,
    // even for people with one name.
    plugin.maySubmit = () =>
        plugin.metadata.optional ||
        (plugin.first.trim().length > 0 &&
        plugin.last.trim().length > 0);

    plugin.loadValue = (nameObj) =>
        Object
            .entries(nameObj)
            .reduce((p, [k, v]) =>
                Object.assign(p, { [k]: v || '' }), plugin);
    /**
 * @typedef {Object} Name
 * @property {string} first
 * @property {string} middle
 * @property {string} last
 * @property {string} prefix
 * @property {string} suffix
 */

    /**
 * @returns {Name}
 */
    plugin.encode = () => ({
        $objectType: 'Name',
        first: typeof plugin.first === 'string' ? plugin.first.trim() : '',
        middle: typeof plugin.middle === 'string' ? plugin.middle.trim() : '',
        last: typeof plugin.last === 'string' ? plugin.last.trim() : '',
        prefix: typeof plugin.prefix === 'string' ? plugin.prefix.trim() : '',
        suffix: typeof plugin.suffix === 'string' ? plugin.suffix.trim() : '',
    });

    return plugin;
}
