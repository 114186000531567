import { m } from '#/browser-framework';

const Form = {
    view({
        attrs: {
            f,
        },
    }) {
        return m('.MultiLineTextInput',
            m('label', { for: f.metadata.memberName }, f.labelText),
            m('textarea', {
                id: f.metadata.memberName,
                value: f.value,
                oninput(e) {
                    f.value = e.target.value;
                },
            }));
    },
};

export default function MultiLineTextInput(plugin) {
    plugin.view = Form;

    return plugin;
}
