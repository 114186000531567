import { bem, m } from '#/browser-framework';
import { pointable, Button, Named } from '#/browser-framework/comps';


const FieldListRow = {
    btx: bem`FieldListRow content controls remove`,
    view({ attrs: { onremove }, children }) {
        const { btx } = FieldListRow;

        return m(btx.block,
            m(btx.content, children),
            m(btx.controls,
                m(Button, {
                    flags: {
                        danger: true,
                    },
                    onpress: onremove,
                }, m(Named.Icon, { name: 'x' }))));
    },
};

const FieldListView = {
    btx: bem`FieldList add controls items label row`,
    view({
        attrs: {
            f,
        },
    }) {
        const { value, labelText } = f;
        const { btx } = FieldListView;

        return m(pointable(btx.block),
            m(btx.label,
                labelText),
            m(btx.controls,
                m(btx.add,
                    m(Button, {
                        flags: {
                            primary: true,
                        },
                        onpress: f.add,
                    }, 'Add new item'))),
            m(btx.items,
                (f.isBlank())
                    ? 'This list is empty.'
                    : value.map((el, key) => m(btx.row, { key },
                        m(FieldListRow, { onremove: () => f.remove(key) }, m(el.view, {f: el}))))));
    },
};


export default function FieldList(plugin, _, factory) {
    const containedType = plugin.metadata.dataType.split(/[\[\]]/g).filter((v) => v)[1];

    plugin.view = FieldListView;
    plugin.value = [];

    plugin.add = () => {
        return plugin.value.push(
            factory(
                plugin.registry.get(containedType),
                plugin.registry));
    };

    plugin.remove = (i) =>
        plugin.value.splice(i, 1);

    plugin.isBlank = () =>
        plugin.value.length === 0;

    plugin.maySubmit = () =>
        ((plugin.metadata.optional && plugin.isBlank()) ||
        plugin.value.every((k) => k.maySubmit()));

    plugin.encode = () =>
        plugin.value.reduce((p, k) =>
            p.concat([k.encode()]), []);

    return plugin;
}
