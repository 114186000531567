import { m } from '#/browser-framework';

import { ConfirmDialog } from './ConfirmDialog';


export const DeleteConfirmationDialog = {
    view({
        attrs,
        children,
    }) {
        return m(ConfirmDialog,
            Object.assign({}, attrs, {
                confirmText: 'DELETE',
                continueButtonText: 'Delete',
            }),
            m('h2', 'Really delete? This action can NOT be undone.'),
            (attrs.error) && m('p', attrs.error.message),
            children);
    },
};
